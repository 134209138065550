import { Region, Country } from './countries.interface';
import { PQRSConfig } from './pqrs.interface';
import { UserRoles } from './users.interface';

export enum Experiments {
  skipButton = 'skipButton',
  approvePrByEmail = 'approvePR',
  visitorLogReminder = 'visitorLogReminder',
}

export enum UnitsOfTime {
  hours = 'hours',
  days = 'days',
  weeks = 'weeks',
}

export enum BuildingStatus {
  active = 'active',
  inactive = 'inactive',
}

export enum BuildingType {
  free = 'free',
  basic = 'basic',
  trial = 'trial',
  pro = 'pro',
  churn = 'churn',
  delete = 'delete',
}

export enum Timezome {
  panama = 'America/Panama',
}

export class BuildingsExperiments {
  type: Experiments;
  enabled: boolean;
  config?: {
    estimatedViews: number;
    timeFrame: number;
    unitTime: UnitsOfTime;
  };
}

export class FileUpload {
  size: number;
  filename: string;
  type: string;
  url: string;
  id: string;
  nameServer: string;
}

export class BasicColorConfig {
  primary: string;
  secondary: string;
}

export class TextConfig {
  general: BasicColorConfig;
}

export class ImageBuilding {
  logo: string;
}

export class GeneralButtonConfig {
  agreed: string;
  deneid: string;
}

export class ButtonsConfig {
  general: GeneralButtonConfig;
}

export class ColorsConfig {
  app?: BasicColorConfig;
  icons?: BasicColorConfig;
  text?: TextConfig;
  buttons?: ButtonsConfig;
  color: string;
}

export class Personalization {
  enable: boolean;
  customPersonalization: Boolean;
  name?: string;
  images?: ImageBuilding;
  colors?: ColorsConfig;
}

export class ChatModule {
  enable: UserRoles[];
}

export class InvitatonModule {
  enable: boolean;
  invitationLeft?: boolean;
  maxGuests?: number;
  region?: Region | string;
  frequentAccess?: boolean;
  deliveries: boolean;
  providers: boolean;
  packages: boolean;
}

export interface BuildingConfig {
  personalization: Personalization;
  chat: ChatModule;
  chatV2: {
    enable: boolean;
    roles?: UserRoles[];
  };
  invitation: InvitatonModule;
  timezone: string;
  notice: {
    enable: boolean;
  };
  comunily: {
    enable: boolean;
    sector?: any | string;
    community?: string;
  };
  reservation: {
    enable: boolean;
  };
  services: {
    enable: boolean;
    list?: string[];
  };
  accountState: {
    enable: boolean;
  };
  experiments: BuildingsExperiments[];
  lastVisitorReminder: Date;
  homeV3: boolean;
  munilyTrucks: string[];
  pqrs: {
    enable: boolean;
    config: PQRSConfig;
  };
  onboarding: OnboardingConfig;
  operations: OperationsConfig;
}

export class OperationsConfig {
  executive: string | any;
  seller: string | any;
  onfield: string | any;
  securityCompany: string | any;
  zone: string | any;
  device: {
    brand: string;
    model: string;
    operator: string;
    number: string;
  };
}

export enum ActivationPhases {
  onboarding = 'onboarding',
  dbLoaded = 'dbLoaded',
  rigurousActivation = 'rigurousActivation',
}

export class OnboardingConfig {
  status: ActivationPhases;
  dateChange: Date;
}

export interface Building {
  id: string;
  country: Country;
  status: BuildingStatus;
  type: BuildingType;
  sid: number;
  dynamicLinkSidTmpUser: {
    admin: {
      shortLink: string;
      previewLink: string;
    };
    resident: {
      shortLink: string;
      previewLink: string;
    };
  };
  coordinates: {
    _latitude: number;
    _longitude: number;
  };
  search: string[];
  name: string;
  address: string;
  phone: string;
  qr: FileUpload;
  pdf: FileUpload;

  config: BuildingConfig;
  updatedAt?: Date;
  createdAt?: Date;
  updated_at?: Date;
  created_at?: Date;

  // Version 1 de building
  custom: boolean;
  customConfig: {
    colors: {
      app: {
        primary: string;
        secondary: string;
      };
    };
    name: string;
    images: {
      logo: string;
    };
  };
}

export enum ColumnsBuildingTable {
  units = 'units',
  status = 'status',
  actions = 'actions',
}

export enum ColumnsExperimentsTable {
  name = 'name',
  description = 'description',
  actions = 'actions',
}

export enum ExecutiveStatus {
  active = 'active',
  inactive = 'inactive',
  delete = 'deleted',
}

export interface Executive {
  name: string;
  status: ExecutiveStatus;
  createdAt: Date;
  updatedAt: Date;
  search: string[];
}

export interface OperationZoneProcess {
  title: string;
  btnAccept: string;
  btnCancel: string;
  placeholder?: string;
  image?: string;
  description?: string;
}

export interface ListBuildings {
  buildingName: string;
  buildingId: string;
  isMacro: boolean;
  status: BuildingStatus;
}