export const environment = {
  production: true,
  apiUrl: 'https://api-dev.munily.xyz/api',
  newApiUrl: 'https://api-dev.munily.xyz',
  baseUrl: 'https://dashboard-dev.munily.xyz',
  socketUrl: 'https://socket-dev.munily.xyz',
  firebase: {
    apiKey: 'AIzaSyBkEM9yTL3w_GBx-JNb20ySK7IPvp_LHcA',
    authDomain: 'munily-dev-f8b14.firebaseapp.com',
    databaseURL: 'https://munily-test.firebaseio.com',
    projectId: 'munily-dev-f8b14',
    storageBucket: 'munily-dev-f8b14.appspot.com',
    messagingSenderId: '263851461501',
    appId: '1:263851461501:android:ea2f0839eb20e7e4',
  },
  geoapifyUrl: 'https://api.geoapify.com/v1/geocode/search?text',
  geoapifyApiKey: 'db85710bfb124a93a7d465a5b9355bb8',
  amplitudeApiKey: '46c912ce0505f555a0cfc2682c2a2641',
  amplitudeEnabled: true,
  envDeployed: 'dev',
  newApiBackend: 'https://api-mono-dev.munily.xyz/api'
};
