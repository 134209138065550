<section class="detail__close-modal">
  <button mat-icon-button (click)="closeModal()"><mat-icon fontSet="material-icons-outlined">close</mat-icon></button>
</section>
<section class="emergency__modal">
  <section class="emergency__modal-detail">
    <section class="emergency__modal-header">
      <div class="header-title-left">
        <h5 class="secondary m-0 h5_700">{{ 'emergency.details.title' | translate }}</h5>

        <div class="header-subtitle">
          <mat-icon class="moving_ministry_icon" svgIcon="moving_ministry"></mat-icon>
          <h6 class="secondary m-0 h6_500">{{ emergency.building.name }}</h6>
        </div>
      </div>
      <div class="header-title-rigth">
        <div class="custom__status" [class]="emergency.status">
          {{ 'emergency.status.' + emergency.status | translate | uppercase }}
        </div>
      </div>
    </section>

    <section class="emergency__modal-body">
      <h6 class="secondary m-0 h6_500">{{ 'emergency.details.generalInfo' | translate }}</h6>

      <div class="body__user-profile">
        <div class="body-left">
          <div class="left-profile">
            <div>
              <img [src]="emergency.user.photoUrl">
            </div>

            <div>
              <p class="secondary m-0 body-2">{{ 'emergency.details.reportedByResident' | translate }}</p>
              <p class="secondary m-0 subtitle-1">{{ emergency.user.name }}</p>
            </div>
          </div>
        </div>
        <div class="body-rigth">
          <div class="body-rigth-user">
            <div class="profile">
              <div class="icon">
                <mat-icon fontSet="material-icons-outlined">calendar_today</mat-icon>
              </div>
              <div class="text">
                <label for="unit">{{ 'emergency.details.unit' | translate }}</label>
                <p id="unit">{{ emergency.unit.number }}</p>
              </div>
            </div>
            <div class="profile">
              <div class="icon">
                <mat-icon fontSet="material-icons-outlined">calendar_today</mat-icon>
              </div>
              <div class="text">
                <label for="lastStatusAt">{{ 'emergency.details.emergencyDate' | translate }}</label>
                <p id="lastStatusAt">{{ formatDate(emergency.lastStatusAt, timeZone, 'DD MMM. yyyy') }}</p>
              </div>
            </div>
            <div class="profile">
              <div class="icon">
                <mat-icon fontSet="material-icons-outlined">schedule</mat-icon>
              </div>
              <div class="text">
                <label for="lastStatusAtHours">{{ 'emergency.details.emergencyTime' | translate }}</label>
                <p id="lastStatusAtHours">{{ formatDate(emergency.lastStatusAt, timeZone, 'h:mm a') }}</p>
              </div>
            </div>
            <div class="profile">
              <div class="icon">
                <mat-icon fontSet="material-icons-outlined">person_alert</mat-icon>
              </div>
              <div class="text">
                <label for="assistedBy">{{ 'emergency.details.assistedBy' | translate }}</label>
                <p id="assistedBy">{{ assistedBy?.updateBy?.name ?? '--' }}</p>
              </div>
            </div>
            <div class="profile">
              <div class="icon">
                <mat-icon fontSet="material-icons-outlined">timer</mat-icon>
              </div>
              <div class="text">
                <label for="assistedByTime">{{ 'emergency.details.responseTime' | translate }}</label>
                <p id="assistedByTime">{{ this.formatDuration ?? '--' }}</p>
              </div>
            </div>
          </div>
          <div class="space">
            <mat-divider></mat-divider>
          </div>
          <div class="body-rigth-guard">
            <div class="profile">
              <div class="icon">
                <mat-icon fontSet="material-icons-outlined">title</mat-icon>
              </div>
              <div class="text">
                <label for="reason">{{ 'emergency.details.reason' | translate }}</label>
                <p id="reason">{{ emergency?.reason ?? '--' }}</p>
              </div>
            </div>
            <div class="profile">
              <div class="icon">
                <mat-icon fontSet="material-icons-outlined">comment</mat-icon>
              </div>
              <div class="text">
                <label for="reason">{{ 'emergency.details.guardComment' | translate }}</label>
                <p id="reason">{{ emergency?.comment ?? '--' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="emergency__modal-footer">
      <common-buttons-filled [label]="'emergency.details.contact' | translate"
        [disabled]="isSuperAdmin || (isChatEnableBuilding && !chatEnable) || (!isChatEnableBuilding && !chatEnable)"
        [iconPosition]="false" [icon]="'chat'" [typeIcon]="'outlined'"
        (output)="onEventButton($event)"></common-buttons-filled>
    </section>
  </section>
</section>