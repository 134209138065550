import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Building } from '../interface/buildings.interface';
import { CovertureZoneResponse } from '../interface/services.interface';
import { unitsResponse } from '../interface/units.interface';
import { DataService } from './data.service';
import { CreateDoor } from '../interface/door.interface';

export interface UpdateBuildingMacro {
  enable: boolean;
  buildings: string[];
}

@Injectable()
export class BuildingService extends DataService {
  currentBuilding: Building;

  headers: HttpHeaders;
  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>) {
    super(injector, store);
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')).token,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')).fbToken,
    });
    this.currentBuilding = this.getInitialBuilding();
  }

  listBuildings(page, limit, search, find): any {
    let params: string = search ? `&${search}` : '';
    if (!params.includes("status")) params += "&status=active";
    find = find ? `&search=${find}` : '';

    return this.http
      .get(`${environment.newApiUrl}/api/super/v1/buildings?page=${page}&rowsPerPage=${limit}${params}${find}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log('errr: ', err);
        Promise.reject(err);
      });
  }

  getMunilyServices() {
    return this.http.get(`${environment.apiUrl}/super/v1/munily-trucks`, { headers: this.headers });
  }

  generateQrForBuilding(buildingId: string, imageBase64) {
    return this.http.post(`${environment.apiUrl}/super/v1/buildings/${buildingId}/generate-qr`, imageBase64, { headers: this.headers });
  }

  getBuildings(params?: HttpParams) {
    return this.http.get(`${environment.apiUrl}/super/v1/buildings/list`, { headers: this.headers, params });
  }

  getUnitsByBuilding(buildingId: any) {
    return this.http.get(`${environment.apiUrl}/super/v1/units/${buildingId}/all`);
  }

  getUnitsByBuildingWithOwners(buildingId: any) {
    return this.http.get(`${environment.apiUrl}/super/v1/units/${buildingId}/allWithOwners`, { headers: this.headers });
  }

  newStoreBuilding(data) {
    if (data.building.operationZone) {
      data.building.operationZone = data.building.operationZone.id;
    }
    return this.http.post(`${environment.apiUrl}/super/v1/buildings`, data, { headers: this.headers });
  }

  newUpdateBuilding(data, id) {
    return this.http.put(`${environment.apiUrl}/super/v1/buildings/${id}`, data, { headers: this.headers });
  }

  newUpdateBuildingConfig(data, id) {
    return this.http.put(`${environment.apiUrl}/super/v1/buildings/${id}/config`, data, { headers: this.headers });
  }

  updateOperationsConfig(data, id) {
    return this.http.put(`${environment.apiUrl}/super/v1/buildings/${id}/config-ops`, data, { headers: this.headers });
  }

  createSecurityCompany(data) {
    return this.http.post(`${environment.apiUrl}/super/v1/security-companies`, data, { headers: this.headers });
  }

  editSecurityCompany(id: string, data) {
    return this.http.put(`${environment.apiUrl}/super/v1/security-companies/${id}`, data, { headers: this.headers });
  }

  removeSecurityCompany(id: string) {
    return this.http.delete(`${environment.apiUrl}/super/v1/security-companies/${id}`, { headers: this.headers });
  }

  updateBuildingConfig(data, id) {
    return this.http.put(`${environment.apiUrl}/admin/v1/buildings/${id}/config`, data, { headers: this.headers });
  }

  getOneBuildingById(id) {
    return this.http.get(`${environment.apiUrl}/super/v1/buildings/${id}`);
  }

  getBuildingMacroConfig(id: string) {
    return this.http.get(`${environment.apiUrl}/super/v1/buildings/admin-macro/${id}`, { headers: this.headers });
  }

  updateConfigBuildingMacro(id: string, data: UpdateBuildingMacro) {
    return this.http.put(`${environment.apiUrl}/super/v1/buildings/admin-macro/update/${id}`, data, { headers: this.headers });
  }

  storeBuilding(data) {
    return this.http
      .post(`${environment.apiUrl}/building`, data)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log('errr: ', err);
        return Promise.reject(err);
      });
  }

  viewBuilding(id) {
    return this.http
      .get(`${environment.apiUrl}/building/${id}?wUsers=true&wApart=true&roles=admin`, id)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateBuilding(data) {
    return this.http
      .put(`${environment.apiUrl}/building/${data.building.id}`, data)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  updateStatusBuilding(id: string, status: string): Observable<Building> {
    return this.http.put<Building>(`${environment.apiUrl}/super/v1/buildings/services/status/${id}`, { status });
  }

  updateBuildingPlan(id, plan) {
    return this.http
      .put(`${environment.apiUrl}/building/${id}/update-plan`, { plan })
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  fetchComments(buildingId) {
    return this.http
      .get(`${environment.apiUrl}/comment?building=${buildingId}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  submitComment(data) {
    return this.http
      .post(`${environment.apiUrl}/comment`, data)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  getRegions() {
    return this.http.get(`${environment.apiUrl}/regions`);
  }

  donwloadInfoInstall(id) {
    window.open(`${environment.apiUrl}/building/${id}/generateInfoFirstLogin`, '_blank');
  }

  getEmailUserUnit(unitId: string) {
    return this.http.put(`${environment.apiUrl}/admin/v1/units/users/${unitId}`, { headers: this.headers });
  }

  getCovertureZones() {
    return this.http.get(`${environment.apiUrl}/super/v1/munily-trucks/region`) as Observable<CovertureZoneResponse>;
  }

  unitsMapper(units: unitsResponse[]) {
    return units.map((unit) => {
      return {
        id: unit.unitId,
        number: unit.unitNumber,
      };
    });
  }

  updateExperiments(buildingId: string, experiments) {
    return this.http.post(`${environment.apiUrl}/super/v1/buildings/${buildingId}/experiments`, { experiments }, { headers: this.headers });
  }

  private getInitialBuilding(): Building {
    return this.getBuilding();
  }

  getInformationDevices(userId: string, params: HttpParams) {
    return this.http.get(`${environment.apiUrl}/super/v1/buildings/${userId}/devices`, { params });
  }

  updateConfigQpassBuilding(buildingId: string) {
    return this.http.get(`${environment.apiUrl}/super/v1/buildings/${buildingId}/config/qpass`);
  }

  updateConfigAccessDoorBuilding(buildingId: string) {
    return this.http.get(`${environment.apiUrl}/super/v1/buildings/${buildingId}/config/access-door`);
  }

  updateConfigQpassVehicleBuilding(buildingId: string) {
    return this.http.get(`${environment.apiUrl}/super/v1/buildings/${buildingId}/config/qpass/vehicle`);
  }

  updateConfigQpassExtendTimeBuilding(buildingId: string, payload: { enable: boolean, extendTime: number }) {
    return this.http.patch(`${environment.apiUrl}/super/v1/buildings/${buildingId}/config/qpass/extend-time`, payload);
  }

  getDoors(params: HttpParams) {
    return this.http.get(`${environment.apiUrl}/super/v1/door`, { params });
  }

  createDoor(payload: CreateDoor) {
    return this.http.post(`${environment.apiUrl}/super/v1/door`, payload);
  }

  updateDoor(doorId: string, payload: CreateDoor) {
    return this.http.put(`${environment.apiUrl}/super/v1/door/${doorId}`, payload);
  }

  getDoorsBuilding(buildingId: string) {
    return this.http.get(`${environment.apiUrl}/admin/v1/door/${buildingId}`);
  }

  getVehicleDataStepOptional(buildingId: string) {
    return this.http.get(`${environment.apiUrl}/super/v1/buildings/${buildingId}/step/vehicle`);
  }

  getBuildingConfigQpassVehicle(buildingId: string) {
    return this.http.get(`${environment.apiUrl}/super/v1/buildings/config/${buildingId}/qpass/vehicle`, { headers: this.headers });
  }

  getActiveMaiaiFaceRecognition() {
    return this.http.get(`${environment.apiUrl}/admin/v1/buildings/config/maia/face-recognition`, { headers: this.headers });
  }
}
