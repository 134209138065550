<section class="detail__close-modal">
  <button mat-icon-butto (click)="close()"><mat-icon>close</mat-icon></button>
</section>

<section class="detail-security-modal">
  <section class="header">
    <div class="title detail-type-details">
      <h3>{{ 'frequentAccess.accessDetail' | translate }}</h3>
      <p>
        {{ ('menu.' + accesTypeTitle) | translate }}
      </p>

      <div class="custom__status m-0" [class]="accessSelected.accessEntryStatus">{{ ('status.invitations.' +
        accessSelected.accessEntryStatus) | translate | uppercase }}</div>
    </div>
  </section>

  <section>
    <div>
      <h5>{{ 'frequentAccess.doorPath' | translate }}</h5>
    </div>

    <access-timeline [accessEntries]="accessSelected.accessEntry"></access-timeline>
  </section>
  <div>
    <hr>
  </div>
  <section class="container__modal-body">
    <div class="body__guest-header">
      <h2>{{ accessSelected.guest.name}}</h2>
      <p>
        | {{ ('frequentAccess.accessTypes.' + (accessSelected.personInvitationType
        ? accessSelected.personInvitationType
        : accessSelected.type)) | translate }}
      </p>

    </div>

    <div class="body__guest-subheader">
      <div class="subHeader__documents">
        <div class="subHeader__documents-column1">
          <div class="document-container">
            <div class="icon">
              <mat-icon svgIcon="badge"></mat-icon>
            </div>
            <div class="text">
              <label for="identification">{{ 'frequentAccess.document' | translate }}</label>
              <p id="identification">{{ accessSelected.guest.dni }}</p>
            </div>
          </div>

          <div class="document-container">
            <div class="icon">
              <mat-icon svgIcon="mail"></mat-icon>
            </div>
            <div class="text">
              <label for="identification">{{ 'superadministration.createUser.email' | translate }}</label>
              <p id="identification">{{ accessSelected.guest.email }}</p>
            </div>
          </div>

          <div class="document-container" *ngIf="!isBusiness">
            <div class="icon">
              <mat-icon svgIcon="factory"></mat-icon>
            </div>
            <div class="text">
              <label for="identification">{{ 'frequentAccess.management.companyDestination' | translate
                }}</label>
              <p id="identification">{{ accessSelectedInfo.building &&
                accessSelectedInfo.building.name ? accessSelectedInfo.building.name : '' }}</p>
            </div>
          </div>

        </div>
        <div class="subHeader__documents-column2">
          <div class="document-container">
            <div class="icon">
              <mat-icon svgIcon="call_end"></mat-icon>
            </div>
            <div class="text">
              <label for="identification">{{ 'superadministration.createUser.phone' | translate }}</label>
              <p id="identification">{{ accessSelected.guest.phone }}</p>
            </div>
          </div>

          <div class="document-container">
            <div class="icon">
              <mat-icon svgIcon="garage_door"></mat-icon>
            </div>
            <div class="text">
              <label for="identification">{{ 'frequentAccess.destinationPlace' | translate
                }}</label>
              <p id="identification">{{ accessSelected.unit.number }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="body-guest-image">
        <div class="file-card" *ngIf="accessSelected.guest && accessSelected.guest?.photo">
          <img [src]="accessSelected.guest?.photo" alt="Image guest" class="file-image">
        </div>

        <div class="image-placeholder" *ngIf="!accessSelected.guest && accessSelected.guest?.photo">
          <i class="image-icon"></i>
          <p>{{ 'frequentAccess.noImageRecord' | translate }}</p>
        </div>
      </div>
    </div>
  </section>

  <div *ngIf="isAccessFrequent">
    <hr>
  </div>
  <section class="container__modal-entry" *ngIf="isAccessFrequent">
    <div class="entry__body-title">
      <h2>{{ 'frequentAccess.entryRange' | translate }}</h2>
    </div>

    <div class="entry__body-body">
      <div class="body__dynamic-entry">
        <div class="document-container">
          <div class="icon">
            <mat-icon svgIcon="date_range"></mat-icon>
          </div>
          <div class="text">
            <label for="identification">{{ 'frequentAccess.expires' | translate}}</label>
            <p id="identification"> {{ accessSelected.frequentAccessId.dueDate | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>
        <div class="document-container">
          <div class="icon">
            <mat-icon svgIcon="date_range"></mat-icon>
          </div>

          <div class="text">
            <label for="identification">{{ 'modal.comment' | translate }}</label>
            <p id="identification"> {{ accessSelected.comment }}</p>
          </div>
        </div>
      </div>

      <div class="document-container">
        <div class="icon">
          <mat-icon svgIcon="date_range"></mat-icon>
        </div>

        <div class="text">
          <label for="identification">{{ 'superadministration.table.daysAccess' | translate }}</label>
          <div class="custom__text-schedule">
            <ng-container *ngFor="let day of accessSelected.frequentAccessId['schedule'] | keyvalue">
              <ng-container *ngFor="let schedule of day.value; let i = index">
                <p>
                  {{ ('abbreviatedDays.' + days[day.key]) | translate }}
                </p>
                <p>
                  {{ schedule.entryTime }} a {{ schedule.departureTime }}
                </p>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div>
    <hr>
  </div>
  <section class="container__modal-vehicle" *ngIf="validVehicle">
    <div class="vehicle__body-title">
      <h5>{{ 'frequentAccess.vehicleOption.title.vehicleData' | translate }}</h5>
    </div>

    <div class="vehicle__body">
      <div class="document-container">
        <div class="icon">
          <mat-icon svgIcon="transportation"></mat-icon>
        </div>
        <div class="text">
          <label for="identification">{{ 'frequentAccess.vehicleOption.title.typeVehicle' | translate }}</label>
          <p id="identification">{{ accessSelected.vehicle && accessSelected.vehicle.typeVehicle ?
            ('frequentAccess.vehicleOption.inputsVehicle.vehicle.' + accessSelected.vehicle.typeVehicle | translate ) :
            ''}}</p>
        </div>
      </div>

      <div class="document-container">
        <div class="icon">
          <mat-icon svgIcon="garage"></mat-icon>
        </div>
        <div class="text">
          <label for="identification">{{ 'frequentAccess.vehicleOption.inputsVehicle.plateLicense' | translate
            }}</label>
          <p id="identification">{{ accessSelected.vehicle && accessSelected.vehicle.plate ?? '' }}</p>
        </div>
      </div>

      <div class="document-container">
        <div class="icon">
          <mat-icon svgIcon="traffic_jam"></mat-icon>
        </div>

        <div class="text">
          <label for="identification">{{ 'frequentAccess.vehicleOption.inputsVehicle.modelVehicle' | translate
            }}</label>
          <p id="identification">{{ accessSelected.vehicle && accessSelected.vehicle.model ?? '' }}</p>
        </div>
      </div>

      <div class="document-container">
        <div class="icon">
          <mat-icon svgIcon="car_crash"></mat-icon>
        </div>
        <div class="text">
          <label for="identification">{{ 'frequentAccess.vehicleOption.inputsVehicle.colorVehicle' | translate
            }}</label>
          <p id="identification">{{ accessSelected.vehicle && accessSelected.vehicle.color ?? '' }}</p>
        </div>
      </div>

      <div class="document-container">
        <div class="icon">
          <mat-icon svgIcon="car"></mat-icon>
        </div>
        <div class="text">
          <label for="identification">{{ 'frequentAccess.vehicleOption.inputsVehicle.brandVehicle' | translate
            }}</label>
          <p id="identification">{{ accessSelected.vehicle && accessSelected.vehicle.brand ?? '' }}</p>
        </div>
      </div>
    </div>
  </section>

  <section class="container__modal-vehicle-else" *ngIf="!validVehicle">
    <mat-icon svgIcon="info">info</mat-icon>
    <p>{{ 'frequentAccess.securityCenter.accessCreatedWithoutVehicleData' | translate }}</p>
  </section>

</section>


<!-- MODALS -->
<ng-template #extendVisit let-c="close" let-d="dismiss">
  <app-extend-time-visiting [invitationId]="invitationId" [frequentAccessId]="frequentAccessId"
    [hourAccess]="hourAccess" (closeExtendTime)="closeExtendVisit($event)"></app-extend-time-visiting>
</ng-template>