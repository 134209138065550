import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FEATURES_EXCLUDE_SIDEBAR } from 'src/app/interface/features.interface';
import { DataService } from '../../services/data.service';
import { GeneralConfigService } from '../../services/general-config.service';
import { ROUTES, SUB_MENU_ITEMS, mainFeatures } from './menu-items';
import { NavigationEnd, Router } from '@angular/router';
import { ACTION_LOADING } from 'src/app/component/store/actions/actions';


declare var $: any;
enum MacroBuildingType {
  macrocommunity = 'macrocommunity',
  community = 'community'
}
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  currentLanguage: string = 'es';
  currentUrl: string = '';
  isRailMode: boolean = false;
  public sidebarnavItems = [];
  contability: boolean = false;

  constructor(public dataService: DataService, private translateService: TranslateService, public generalConfigService: GeneralConfigService, private router: Router) { }

  // End open close
  async ngOnInit() {
    this.currentLanguage = this.translateService.store.currentLang;
    setTimeout(() => {
      const user = this.dataService.getUser();
      const buildingCofig = this.dataService.getBuilding().config;
      const roles = user.roles ? user.roles : 'superAdmin';
      const isMacro:boolean = !!user.buildings[0].config?.macrocommunity?.enable;
      const isAMacroChild:boolean = isMacro && user.buildings[0].config?.macrocommunity?.communities ? !(this.isAMacroFather(user.buildings[0].config?.macrocommunity.communities)): false;
      if (user.superUser) {
        this.sidebarnavItems.push(ROUTES[mainFeatures.munilyPay]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.buildings]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.communities]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.usersSuperAdmin])
        this.sidebarnavItems.push(ROUTES[mainFeatures.services]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.releases]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.knowledgeCenter]);
        this.sidebarnavItems.push(ROUTES[mainFeatures.door]);
        this.sidebarnavItems = this.sortListByTitle(this.sidebarnavItems);
        this.sidebarnavItems = [(ROUTES[mainFeatures.dashboardSuperAdmin]), ...this.sidebarnavItems];


      } else if (roles.indexOf('customized') > -1) { //CUSTOMIZED AMINISTRATORS
        this.sidebarnavItems.length = 0;
        this.builtCustomizedMenu(user.features);
        this.sidebarnavItems = this.sortListByTitle(this.sidebarnavItems);
      } else {

        if ((roles.indexOf('admin') > -1) && (!isMacro || isAMacroChild)) {  //STANDAR ADMINISTRATOR
          // Optional items, which depends on the general configuration conditions
          this.dataService.isLoading({ action: ACTION_LOADING, payload: true });
          this.generalConfigService.getGeneralConfigFeatures().subscribe((config: any) => {
            //CHAT
            if (buildingCofig.chatV2 && buildingCofig.chatV2.enable){
              this.sidebarnavItems.splice(1, 0, ROUTES[mainFeatures.chats]);
            }
            //AccountStatatements
            if (config.data.feature && config.data.feature.contability) this.contability = true;
            if (this.contability) {
              this.sidebarnavItems.splice(1, 0, ROUTES[mainFeatures.accountStatements]);
            }
            //PQRS
            if (buildingCofig.pqrs && buildingCofig.pqrs.enable){
              this.sidebarnavItems.splice(1, 0, ROUTES[mainFeatures.pqrs]);
            }
            //MunilyPay
            if(buildingCofig.fintech && buildingCofig.fintech.enable) this.sidebarnavItems.push(ROUTES[mainFeatures.munilyPayTransactions]);
            //AccessControl 
            const accessControl = buildingCofig.accessControl;
            if(accessControl && accessControl.preRegisterVisit && (accessControl.preRegisterVisit.enable || accessControl.vigilantVisit.enable)){
              this.sidebarnavItems.push(ROUTES[mainFeatures.controlAccess]);
            }
            //Calls - Llamadas
            if( buildingCofig.directory?.enable || buildingCofig.invitation?.directCallVigilant){
              this.sidebarnavItems.push(ROUTES[mainFeatures.vigilantCalls]);
            }
            //Munily Providers
            if (buildingCofig.munilyPh && buildingCofig.munilyPh.enable){
              ROUTES[mainFeatures.munilyProviders].path = buildingCofig.munilyPh.link;
              this.sidebarnavItems.push(ROUTES[mainFeatures.munilyProviders]);
            }
            //General Items
            this.sidebarnavItems.push(
              ROUTES[mainFeatures.notices],
              ROUTES[mainFeatures.reservations],
              ROUTES[mainFeatures.visits],
              ROUTES[mainFeatures.frequentAccess],
              ROUTES[mainFeatures.administrativeDropdown],
              ROUTES[mainFeatures.knowledgeCenter],
              ROUTES[mainFeatures.emergency]
            );
            this.sidebarnavItems = this.sortListByTitle(this.sidebarnavItems);
            //Setting Home after sorting
            this.sidebarnavItems = [(ROUTES[mainFeatures.dashboard]), ...this.sidebarnavItems];
            this.dataService.isLoading({ action: ACTION_LOADING, payload: false });
          },err=>{
            this.dataService.isLoading({ action: ACTION_LOADING, payload: false });
            console.log(err.error.message);
          });
        }else if((roles.indexOf('admin') > -1) && isMacro && !isAMacroChild){ //MACROCOMMUNITY ADMINISTRATORS
          this.sidebarnavItems.push(
            ROUTES[mainFeatures.noticesMacro],
            ROUTES[mainFeatures.reservationsMacro],
            ROUTES[mainFeatures.administrativeMacroDropdown],
            ROUTES[mainFeatures.knowledgeCenter]
          );
          this.sidebarnavItems = this.sortListByTitle(this.sidebarnavItems);
          this.sidebarnavItems = [(ROUTES[mainFeatures.dashboardMacro]), ...this.sidebarnavItems];
        }
      }

      // Get the current URL
      this.currentUrl = this.router.url;

      // Subscribe to router events to handle navigation changes
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.currentUrl = event.url;
        }
      });
    },100);
  }

  private builtCustomizedMenu(features){
    features.forEach((feature) => {
      if (feature.enable) {
        feature.feature.forEach((userFeature) => {
          if (FEATURES_EXCLUDE_SIDEBAR.indexOf(userFeature) < 0) {
            // Feature is a subMenu item
            if (this.checkIsSubmenuItem(userFeature)) {
              const mainMenu = this.getMenuItem(userFeature);
              if (!ROUTES[mainMenu].submenu.includes(ROUTES[userFeature])) ROUTES[mainMenu].submenu.push(ROUTES[userFeature]);
              //Validate if parent Menu Item is in the sideBarNav list, if not added
              if (this.sidebarnavItems.indexOf(ROUTES[mainMenu]) < 0) this.sidebarnavItems.push(ROUTES[mainMenu])
            } else { //Feature is a main menu item
              if (this.sidebarnavItems.indexOf(ROUTES[userFeature]) < 0) this.sidebarnavItems.push(ROUTES[userFeature])
            }
          }
        })
      }
    });
  }

  private checkIsSubmenuItem(userFeature) {
    const subItem = SUB_MENU_ITEMS.find(element => element.submenu === userFeature);
    if (subItem) return true
    return false;
  }

  private getMenuItem(userFeature) {
    const subItem = SUB_MENU_ITEMS.find(element => element.submenu === userFeature);
    if (subItem) return subItem.main
  }

  toggleRailMode(sideNav) {
    this.isRailMode = !this.isRailMode;
    this.showMenu = '0';
  }

  isActive(itemPath: string): boolean {
    return this.currentUrl === itemPath;
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
      this.isRailMode = false;
    }
  }

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  private sortListByTitle(list) {
    return list.sort((a, b) => {
      if (a.submenu?.length > 0) {
        a.submenu = this.sortListByTitle(a.submenu);
      }
      const titleA = this.translateService.instant(a.title).toLowerCase();
      const titleB = this.translateService.instant(b.title).toLowerCase();

      return titleA.localeCompare(titleB, 'es', { sensitivity: 'base' });
    });
  }

  isAMacroFather(communities:any[]):boolean{
    const buildingFatherType = MacroBuildingType.macrocommunity;
    const hasFatherType = communities.findIndex((community)=>{
      return community.type == buildingFatherType;
    })
    return hasFatherType > -1;
  }

  handleMenuItemClick(item: any): void {
    if (item.externalLink) {
      // Handle external link
      window.open(item.path, '_blank');
    } else if (item.submenu?.length > 0) {
      // Handle submenu expansion
      this.addExpandClass(item.title);
    } else {
      // Handle internal navigation
      this.addActiveClass(item.title);
      this.router.navigate([item.path]);
    }
  }
}
