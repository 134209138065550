<!-- <ul class="navbar-nav navigationOriginal" [ngClass]="module != headerModules.accountState ? 'navigationOriginalAccouting' : 'navigationOriginal'">  
  <div class="d-flex align-items-center">
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
      <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" aria-haspopup="true" aria-expanded="false">
        <img src="https://munily-public-cdn.s3.amazonaws.com/side-menu/dashboard/bell.svg" height="27" width="21" />
        <span
          class="position-absolute p-1 border border-light rounded-circle notificationAlert"
          *ngIf="unreadNotices + (messagingService.notificationsMessage ? messagingService.notificationsMessage.length : 0) > 0"
        >
        </span>
      </a>
      <div class="dropdown-menu dropdown-menu-right mailbox animated bounceInDown custom-dropdwon-menu" ngbDropdownMenu>
        <span class="with-arrow">
          <span class="bg-primary"></span>
        </span>
        <ul class="list-style-none">
          <li>
            <div class="drop-title bg-primary text-white">
              <h4 class="m-b-0 m-t-5">
                {{ unreadNotices + (messagingService.notificationsMessage ? messagingService.notificationsMessage.length : 0) }}
                {{ 'header.notification.new' | translate }}
              </h4>
              <span class="font-light">{{ 'dashboard.notification' | translate }}</span>
            </div>
          </li>
          <li>
            <div class="message-center notifications" [perfectScrollbar]="config">
              <div id="messages-notifications" *ngIf="messagingService.notificationsMessage && messagingService.notificationsMessage.length > 0">
                <a
                  href="javascript:void(0)"
                  class="message-item unread-notice"
                  *ngFor="let notificationM of messagingService.notificationsMessage"
                  (click)="goMessage(notificationM)"
                >
                  <span class="mail-contnet">
                    <h6 class="message-title text-uppercase">{{ notificationM.title }}</h6>
                    <span class="mail-desc">{{ notificationM.body }}</span>
                    <span class="time text-uppercase">{{ notificationM.createdAt }}</span>
                  </span>
                </a>
              </div>
              <a
                href="javascript:void(0)"
                class="message-item"
                [ngClass]="{ 'unread-notice': !notification.read }"
                *ngFor="let notification of notifications"
                (click)="readNotice(notification.id, notification.read, notification.activity)"
              >
                <span class="mail-contnet">
                  <h6 class="message-title text-uppercase">{{ notification.title }}</h6>
                  <span class="mail-desc">{{ notification.body }}</span>
                  <span class="time text-uppercase">{{ notification.createdAt }}</span>
                </span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </li>
  </div>
</ul> -->

<div class="main-navbar">
  <div class="header-text-with-prefix-icon justify-content-start">
    <mat-icon class="moving_ministry_icon" svgIcon="moving_ministry"></mat-icon>
    <h6 class="secondary m-0 h6_500" id="buildingNameInTopNav">{{building?.name ?? ''}}</h6>
  </div>
  <div class="nabvar-tools">
    <div id="notificationsMenu">
      <button mat-button class="btn_secondary_text__icon_rigth" aria-label="notifications" [matMenuTriggerFor]="notificationMenu" aria-haspopup="true" aria-expanded="false">
        <span>{{'dashboard.notification' | translate}}</span>
        <mat-icon class="material-symbols-rounded">notifications</mat-icon>
        <span class="position-absolute p-1 border border-light rounded-circle notificationAlert" *ngIf="unreadNotices + (messagingService.notificationsMessage ? messagingService.notificationsMessage.length: 0) > 0"></span>
      </button>
    
      <!-- Notifications dropdown menu -->
      <mat-menu #notificationMenu="matMenu" class="custom-dropdown-menu notificationsMenu">
        <div class="drop-title bg-primary text-white">
          <h4 class="m-b-0 m-t-5">
            {{ unreadNotices + (messagingService.notificationsMessage?.length || 0) }}
            {{ 'header.notification.new' | translate }}
          </h4>
          <span class="font-light">{{ 'dashboard.notification' | translate }}</span>
        </div>
    
        <!-- Message Center -->
        <div class="message-center notifications" style="max-height: 300px; overflow-y: auto;">
          <!-- Messaging Service Messages -->
          <div id="messages-notifications" *ngIf="messagingService.notificationsMessage?.length > 0">
            <button mat-menu-item class="message-item unread-notice" *ngFor="let notificationM of messagingService.notificationsMessage" (click)="goMessage(notificationM)">
              <span class="mail-contnet">
                <h6 class="message-title text-uppercase">{{ notificationM.title }}</h6>
                <span class="mail-desc">{{ notificationM.body }}</span>
                <span class="time text-uppercase">{{ notificationM.createdAt }}</span>
              </span>
            </button>
          </div>
    
          <!-- General Notifications -->
          <button mat-menu-item class="message-item" [ngClass]="{ 'unread-notice': !notification.read }" *ngFor="let notification of notifications" (click)="readNotice(notification.id, notification.read, notification.activity)">
            <span class="mail-contnet">
              <h6 class="message-title text-uppercase">{{ notification.title }}</h6>
              <span class="mail-desc">{{ notification.body }}</span>
              <span class="time text-uppercase">{{ notification.createdAt }}</span>
            </span>
          </button>
        </div>
      </mat-menu>
    </div>

    <!-- Language Menu -->
    <div id="languageMenu">
      <!-- Button that activates the menu-->
      <button mat-button [matMenuTriggerFor]="menu" class="btn_secondary_text__icon_rigth" aria-label="Select Language">
        <span>{{ curLangObj?.lang }}</span>
        <img class="imgFlag" [src]="curLangObj?.img" [alt]="curLangObj?.lang" height="16"/>
      </button>
      <!-- Dropdown menu -->
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let language of languages" (click)="useLanguage(language)" >
          <img class="imgFlag" [src]="language.img" [alt]="language.lang" height="16"/>
          {{ language.lang }}
        </button>
      </mat-menu>
    </div>
    <!-- Profile Menu -->
    <div id="profileMenu">
      <button mat-button class="btn_secondary_text__icon_rigth" [matMenuTriggerFor]="profileMenu" aria-haspopup="true" aria-expanded="false">
        <span>{{ userName }}</span>
        <mat-icon class="material-symbols-rounded">account_circle</mat-icon>
        <!-- <img [src]="photo" alt="user" class="rounded-circle photo-user" width="31" /> -->
      </button>

      <!-- Menú desplegable de perfil -->
      <mat-menu #profileMenu="matMenu" class="custom-dropdown-menu perfilMenu">
        <!-- Encabezado -->
        <div class="d-flex align-items-center bg-primary text-white p-3">
          <img [src]="photo" alt="user" class="rounded-circle" width="50" />
          <div class="m-l-10">
            <h4 class="m-b-0">{{ userName }}</h4>
            <p class="m-b-0">{{ userEmail }}</p>
          </div>
        </div>

        <!-- Opciones del menú -->
        <button mat-menu-item (click)="openModalProfile()">
          <img class="m-l-5" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/icon-profile.svg" alt="profile" />
          {{ 'header.profile.myProfile' | translate }}
        </button>

        <button mat-menu-item (click)="openModalPassword()">
          <img class="m-l-5" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/icon-password.svg" alt="password" />
          {{ 'header.password.title' | translate }}
        </button>

        <mat-divider></mat-divider>

        <button mat-menu-item (click)="logout()">
          <img class="m-l-5" src="https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/icon-loggout.svg" alt="logout" />
          {{ 'header.profile.logout' | translate }}
        </button>
      </mat-menu>
    </div>
    <!-- Multisessions menu -->
    <app-multisession-sidebar></app-multisession-sidebar>
  </div>
</div>


<div *ngIf="module == headerModules.accountState" class="buttonsClick">
  <button [disabled]="onboardingIsNotComplete" #payInp (click)="clickButtonAccounting(accountingButtons.paymentManagment, payInp)" class="btn btnAccountingState   ml-2" [ngClass]="typeSelectButtons === accountingButtons.paymentManagment ? 'btnAccountingActive' : 'btnAccounting'">{{ 'onboarding.managment.selfName' | translate }}</button>
  <button [disabled]="onboardingIsNotComplete" #updInp (click)="clickButtonAccounting(accountingButtons.updateAccounting, updInp)" class="btn btnAccountingState   ml-2" [ngClass]="typeSelectButtons === accountingButtons.updateAccounting ? 'btnAccountingActive' : 'btnAccounting'">{{ 'onboarding.managment.update' | translate }}</button>
  <button [disabled]="onboardingIsNotComplete" #traInp (click)="clickButtonAccounting(accountingButtons.transactions, traInp)" class="btn btnAccountingState   ml-2" [ngClass]="typeSelectButtons === accountingButtons.transactions ? 'btnAccountingActive' : 'btnAccounting'">{{ 'onboarding.transactions.see' | translate }}</button>
  <button [disabled]="onboardingIsNotComplete" #impInp (click)="clickButtonAccounting(accountingButtons.importedFiles, impInp)" class="btn btnAccountingState   ml-2" [ngClass]="typeSelectButtons === accountingButtons.importedFiles ? 'btnAccountingActive' : 'btnAccounting'">{{ 'onboarding.managment.importFiles' | translate }}</button>
  
  <div class="dropdown btn">
    <button 
    [disabled]="onboardingIsNotComplete"
    #editInp
    (click)="clickButtonAccounting('button', editInp)"
    class="btn btnAccountingState dropdown-toggle"
    [ngClass]="typeButton ? 'btnAccountingActive' : 'btnAccounting'"
    type="button"
    id="dropdownMenuButton"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
    >
      {{ 'modal.edit' | translate }}
    </button>
    <div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" (click)="goToAccountingPage(accountingButtons.editDueDate)">{{ 'onboarding.transactions.editExpired' | translate }}</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="goToAccountingPage(accountingButtons.editUnit)">{{ 'onboarding.transactions.editUnits' | translate }}</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="goToAccountingPage(accountingButtons.editPaymentMethod)">{{ 'onboarding.transactions.editPayment' | translate }}</a>
    </div>
  </div>

</div>


<app-change-password #changePassword></app-change-password>

<app-profile #profile (onConfirm)="onConfirm($event)"></app-profile>

<ng-template #logoutModal let-c="close" let-d="dismiss">
  <app-logout-modal [allSessions]="true" (close)="logoutModalResponse($event)"></app-logout-modal>
</ng-template>
