
<mat-sidenav-container class="principal-menu">
  <mat-sidenav #sidenav mode="side" [class.rail-mode]="isRailMode" [opened]="true" id="sideNavRail">
    <div class="menu-header">
      <button class="toggle-menu" mat-button (click)="toggleRailMode(sidenav)">
        <mat-icon fontSet="material-icons-outlined">{{isRailMode ? 'menu' : 'close'}}</mat-icon>
      </button>
      <img *ngIf="isRailMode" class="logo-icon" src="../.././../assets/images/munilyIcon.svg" alt="munily-icon" />
      <img *ngIf="!isRailMode" class="logo" src="https://munily-public-cdn.s3.amazonaws.com/Logos/dashboard/munily_logo_medium.png" alt="munily-logo" />
    </div>
    <mat-nav-list>
      <div *ngFor="let sidebarnavItem of sidebarnavItems">
        <!-- Section Title -->
        <div *ngIf="sidebarnavItem.extralink === true" class="nav-small-cap">
          {{ sidebarnavItem.title }}
        </div>


        <!-- Menu Items -->
        <!-- STANDALONE ITEMS -->
        <mat-list-item *ngIf="(!sidebarnavItem.submenu?.length > 0)"
          [ngClass]="{'active' : isActive(sidebarnavItem.path)}"
          [disableRipple]="showMenu === sidebarnavItem.title"
          (click)="handleMenuItemClick(sidebarnavItem)">
          <a class="sidebar-link icon-left">
            <mat-icon class="material-symbols-rounded" *ngIf="sidebarnavItem.icon">{{ sidebarnavItem.icon }}</mat-icon>
            <span *ngIf="!isRailMode">{{ sidebarnavItem.title | translate }}</span>
            <mat-icon *ngIf="sidebarnavItem.submenu?.length > 0" class="ml-auto material-symbols-rounded">
              {{(showMenu === sidebarnavItem.title) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
            </mat-icon>
          </a>
        </mat-list-item>


        <!-- WITH SUBMENU ITEMS -->
        <mat-list-item [ngClass]="{'active' : isActive(sidebarnavItem.path)}" [disableRipple]="showMenu === sidebarnavItem.title" *ngIf="(sidebarnavItem.submenu?.length > 0)">
          <div *ngIf="(sidebarnavItem.submenu?.length > 0)" class="submenu w-100">
            <a mat-line class="sidebar-link icon-left submenu-name" (click)="addExpandClass(sidebarnavItem.title)">
              <mat-icon class="material-symbols-rounded" *ngIf="sidebarnavItem.icon">{{ sidebarnavItem.icon }}</mat-icon>
              <span *ngIf="!isRailMode">{{ sidebarnavItem.title | translate }}</span>
              <mat-icon  class="ml-auto" class="material-symbols-rounded"> {{(showMenu === sidebarnavItem.title) ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
            </a>
            <mat-nav-list *ngIf="(showMenu === sidebarnavItem.title)" class="second-level">
              <mat-list-item
                *ngFor="let subItem of sidebarnavItem.submenu"
                [ngClass]="{'active' : isActive(subItem.path)}"
                (click)="handleMenuItemClick(subItem)">
                <a class="sidebar-link" mat-line>
                  <span *ngIf="!isRailMode">{{ subItem.title | translate }}</span>
                </a>
              </mat-list-item>
            </mat-nav-list>
          </div>
        </mat-list-item> 
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <!-- Main Content (OUTLET)-->
  <mat-sidenav-content [ngClass]="isRailMode ? 'rail-mode' : 'drawer-mode'" #sideNavContent id="sideNavContent">
    <!-- HEADER NAVBAR -->
    <nav class="navbar top-navbar navbar-expand-md p-0">
      <app-navigation class="w-100 navigation"></app-navigation>
    </nav>
    <section class="pages-outlet-container">
      <router-outlet></router-outlet>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>

