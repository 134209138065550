import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';

import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ApartmentService } from '../../services/apartment.service';
import { AreaService } from '../../services/area.service';
import { FinanceService } from '../../services/finance.service';
import { PlanService } from '../../services/plan.service';
import { UserService } from '../../services/user.service';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { UnitStatus } from 'src/app/interface/units.interface';

export let formatLocale = localStorage.getItem('munily-language');

export const MY_FORMATS = {
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-advance-search',
  templateUrl: './advance-search.component.html',
  styleUrls: ['./advance-search.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: MAT_DATE_LOCALE,
      useValue: formatLocale,
    },
  ],
})
export class AdvanceSearchComponent implements OnInit, OnChanges {
  @Output() onSearch = new EventEmitter();
  @Input() module: string;
  @Input() preSearch: any;
  @Input() isShow: boolean;

  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  userService = this.injector.get(UserService);
  planService = this.injector.get(PlanService);
  apartmentService = this.injector.get(ApartmentService);
  areaService = this.injector.get(AreaService);
  financeService = this.injector.get(FinanceService);

  form: FormGroup;
  status = [
    { _id: 'active', name: 'active' },
    { _id: 'inactive', name: 'inactive' },
  ];
  hasUserActive = [
    { _id: 'active', name: 'status.yes', value: true },
    { _id: 'inactive', name: 'status.not', value: false },
  ];
  reservationStatus = [
    { _id: 'approved', name: 'status.approved' },
    { _id: 'canceled', name: 'status.canceled' },
    { _id: 'rejected', name: 'status.rejected' },
    { _id: 'waiting_payment', name: 'status.waiting_payment' },
    { _id: 'waiting_approve', name: 'status.waiting_approve' },
  ];
  invitationStatus = [
    { _id: 'created', name: 'status.invitations.created' },
    { _id: 'checked', name: 'status.invitations.checked' },
    { _id: 'approved', name: 'status.invitations.approved' },
    { _id: 'created_approved', name: 'status.invitations.created_approved' },
    { _id: 'concreted', name: 'status.invitations.concreted' },
    { _id: 'not_completed', name: 'status.invitations.not_completed' },
  ];
  roles = [
    { _id: 'tenant', name: 'modal.selectInput.tenant' },
    { _id: 'vigilant', name: 'modal.selectInput.vigilant' },
    { _id: 'admin', name: 'modal.selectInput.admin' },
    { _id: 'proprietor', name: 'modal.selectInput.proprietor' },
  ];
  rolesStaff = [
    { _id: 'vigilant', name: 'modal.selectInput.vigilant' },
    { _id: 'admin', name: 'modal.selectInput.admin' },
  ];
  rolesTenant = [
    { _id: 'tenant', name: 'modal.selectInput.tenant' },
    { _id: 'proprietor', name: 'modal.selectInput.proprietor' },
  ];
  yesNot = [
    { name: 'Si', value: true },
    { name: 'No', value: false },
  ];
  users = [];
  plans = [];
  apartment = [];
  areas = [];
  typeDebts = [];
  minDate: any;

  unitLoading = false;
  unit$: Observable<any>;
  unitInput$ = new Subject<string>();

  startDate;
  endDate;

  constructor(private injector: Injector, private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
    if (this.module === 'buildings') {
      this.fetchPlan();
    } else if (this.module === 'reservations') {
      this.status = this.reservationStatus;
      this.fetchArea();
      this.fetchApartment();
    } else if (this.module === 'registration') {
      if(this.preSearch){
        this.unitInput$ = new BehaviorSubject<string>(this.preSearch.unit.number.toLowerCase());
      }
      this.fetchApartment();
    } else if (this.module === 'tenant') {
      this.roles = this.rolesTenant;
      this.fetchApartment();
    } else if (this.module === 'visits') {
    } else if (this.module === 'administrative') {
      this.fetchApartment();
    } else if (this.module === 'debts') {
    } else if (this.module === 'invitations') {
      this.status = this.invitationStatus;
      this.fetchApartment();
    } else if (this.module === 'staff') {
      this.roles = this.rolesStaff;
    }

    let language = localStorage.getItem('munily-language');
    language == 'en' ? ((this.startDate = 'Start Date'), (this.endDate = 'End Date')) : ((this.startDate = 'Fecha Inicial'), (this.endDate = 'Fecha Final'));
  }

  ngOnChanges(changes: SimpleChanges) {
    const isShow: SimpleChange = changes.isShow;
    if (isShow && !isShow.currentValue) {
      this.initForm();
    }
  }

  initForm() {
    this.form = this.fb.group({
      name: [null],
      lastName: [null],
      dni: [null],
      apartment: [null],
      admin: [null],
      type: [null],
      status: [null],
      activeUsers: [null],
      startDate: [null],
      endDate: [null],
      startTime: [null],
      endTime: [null],
      unit: [null],
      parking: [null],
      parkings: [null],
      cellar: [null],
      cellars: [null],
      proprietor: [null],
      inCommittee: [null],
      sortBy: [null],
      area: [null],
      months: [null],
      roles: [null],
      typeDebt: [null],
      company: [null],
      licensePlate: [null],
      email: [null],
    });

    if (this.preSearch && this.module === 'reservations') {
      let date = new Date(this.preSearch.date);
      let dateInput = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() + 1 };
      this.form.controls.startDate.setValue(dateInput);
      this.form.controls.endDate.setValue(dateInput);

      this.form.controls.area.setValue(this.preSearch.areaId);
      this.search();
    }

    if (this.preSearch && this.module === 'administrative') {
      if (this.preSearch.hasUserActive === 'active') {
        this.form.controls.activeUsers.setValue(true);
      } else {
        this.form.controls.activeUsers.setValue(false);
      }
      this.search();
    }

    if (this.preSearch && this.module === 'registration') {
      if (this.preSearch.unit) {
        this.form.controls.unit.setValue(this.preSearch.unit.id);
      }
    }
  }

  fetchUser() {
    this.userService
      .fetchUsers(null, null, 'roles=admin')
      .then((r: any) => {
        const aux = [];
        r.data.forEach((u) => {
          aux.push({ _id: u._id, name: u.name, lastName: u.lastName });
          this.users = [...aux];
        });
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }

  fetchPlan() {
    this.plans = ['free', 'basic', 'trial', 'pro', 'churn', 'delete', 'demo'];
  }

  fetchApartment() {
    this.unit$ = concat(
      of([]),
      this.unitInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => (this.unitLoading = true)),
        switchMap((term) => this.apartmentService.fetchApartmentsSimplify(term, UnitStatus.active)),
        tap((response) => {
          this.unitLoading = false;
          return response;
        }),
      ),
    );
  }

  fetchArea() {
    this.areaService
      .fetchArea()
      .then((r: any) => {
        const aux = [];
        r.data.forEach((u) => {
          aux.push({ _id: u.id, name: u.name });
          this.areas = [...aux];
        });
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }

  fetchTypeDebt() {
    this.financeService
      .getTypeDebt('typeDebt')
      .then((r: any) => {
        const aux = [];
        r.data.forEach((t) => {
          aux.push(t);
          this.typeDebts = [...aux];
        });
      })
      .catch((err) => {
        console.log('err: ', err);
      });
  }

  dateSelected(e) {
    this.form.get('endDate').setValue(null);
    this.minDate = e;
  }

  search() {
    const params = this.form.getRawValue();
    const keys = Object.keys(params);
    const query = [];

    params.startDate = this.range.value.start ? moment(this.range.value.start).format('YYYY/M/DD') : '';
    params.endDate = this.range.value.start ? moment(this.range.value.end).format('YYYY/M/DD') : '';

    keys.forEach((k) => {
      if (params[k] || params[k] === false) {
        if (k == 'email') {
          params[k] = params[k].toLowerCase();
        }
        if (k === 'status') {
          const status: string = params[k].toLowerCase();
          let param = status.replace('inactivo', 'inactive');
          param = status.replace('activo', 'active');
          query.push(`${k}=${param}`);
        } else if(k === 'unit'){
          query.push(`${k}=${params[k].toLowerCase()}`);
        } else {
          query.push(`${k}=${params[k]}`);
        }
      }
    });
    this.onSearch.emit(query.join('&'));
  }

  closeFix(event, datePicker) {
    if (event.target.offsetParent == null) datePicker.close();
    else if (event.target.offsetParent.nodeName !== 'NGB-DATEPICKER') datePicker.close();
  }

  limitInput(evt: any) {
    // this.value = this.value.toUpperCase()
    const regex = /[a-zA-Z0-9]|[ ¡!"$%&/()=^\[\]{}+_#¿?']/g;
    if (!evt.key.match(regex)) {
      return false;
    }
    return true;
  }

  clearFilterDate() {
    this.range.controls.start.setValue('');
    this.range.controls.end.setValue('');
    this.ngOnInit();
    this.search();
  }

  clearFieldBtn() {
    this.form.reset();
    this.ngOnInit();
    this.search();
  }
}
