import { Injectable, Injector } from "@angular/core";
import { DataService } from "./data.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { ACTION_LOADING } from "../component/store/actions/actions";
import { Observable } from "rxjs";
import { PaginateResult } from "../interface/data.interface";
import { Emergency } from "../interface/emergency.interface";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { tap } from "rxjs/operators";


@Injectable({ providedIn: 'root' })
export class EmergencyService extends DataService {

  constructor(
    private http: HttpClient,
    protected injector: Injector,
    protected store: Store<any>,
    public toastr: ToastrService,
    public translate: TranslateService
  ) {
    super(injector, store);
  }

  getEmergencies(params: HttpParams): Observable<PaginateResult<Emergency>> {
    return this.http.get<PaginateResult<Emergency>>(`${environment.apiUrl}/admin/v1/emergencies`, { params });
  }

  getEmergenciById(emergencyId: string): Observable<{ data: Emergency }> {
    return this.http.get<{ data: Emergency }>(`${environment.apiUrl}/admin/v1/emergencies/${emergencyId}`);
  }

  getDonwloadEmergencies(params: HttpParams) {
    return this.http.get(`${environment.apiUrl}/admin/v1/emergencies/download`, { params });
  }

  setLoading(isLoading: boolean) {
    this.isLoading({ action: ACTION_LOADING, payload: isLoading });
  }

  getSuperEmergenciesByBuildingId(buildingId: string, params: HttpParams): Observable<PaginateResult<Emergency>> {
    return this.http.get<PaginateResult<Emergency>>(`${environment.apiUrl}/super/v1/emergencies/${buildingId}`, { params });
  }

  getSuperEmergenciesById(emergencyId: string): Observable<{ data: Emergency }> {
    return this.http.get<{ data: Emergency }>(`${environment.apiUrl}/super/v1/emergencies/${emergencyId}`);
  }

  getSuperDonwloadEmergenciesBuildingId(buildingId: string, params: HttpParams) {
    return this.http.get(`${environment.apiUrl}/super/v1/emergencies/download/${buildingId}`, { params });
  }

  formatDurationDateToSeconds(duration: number) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = duration % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
}