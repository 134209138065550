import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CameraPermissionService, PermissionStatus } from 'src/app/services/camera-permission.service';
import * as faceapi from 'face-api.js';
import { FaceapiService } from 'src/app/services/faceapi.service';


export enum RecognitionSteps {
  PreviewRecognition = "PreviewRecognition",
  Recognizing = "Recognizing",
  RecognitionFailed = "RecognitionFailed",
  RecognitionSuccessed = "RecognitionSuccessed",
};

export enum RecognitionErrors {
  Recognition = "Recognition",
  Permissions = "Permissions",
  Camera  = "Camera"
};

@Component({
  selector: 'shared-face-recognition-modal',
  templateUrl: './face-recognition-modal.component.html',
  styleUrls: ['./face-recognition-modal.component.scss']
})
export class FaceRecognitionModalComponent implements OnInit {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvas') canvasElement!: ElementRef<HTMLCanvasElement>;
  @Output() urlPhoto: EventEmitter<any> = new EventEmitter();
  @Output() faceData: EventEmitter<{bestImageBlob: Blob, bestImage: string, bestDescriptor: Float32Array, averageDescriptor: Float32Array}> = new EventEmitter();
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();

  photoControl = new FormControl();

  totalLines = 50;
  scanLines = Array(this.totalLines).fill(0);
  activeScanLines = 0;

  photoCaptured: boolean = false;

  recognitionSteps = RecognitionSteps;
  recognitionStep = RecognitionSteps.PreviewRecognition;

  scanInterval: any;
  errorType:RecognitionErrors | undefined;

  // Recognition results
  averageDescriptor:any;
  descriptors:Float32Array[] = [];
  bestDescriptor: Float32Array | null = null;  // El descriptor más confiable
  bestImage:string | null = null;  // La imagen asociada al descriptor más confiable
  bestImageBlob:Blob | null = null;  // La imagen asociada al descriptor más confiable

  constructor(private cameraPermissionService: CameraPermissionService, private faceapiService : FaceapiService) { }

  ngOnInit(): void {
    this.startRecognition();
  }
  
  // ngOnInit(): void {
  //   this.loadFaceApiModels().then(()=>{
  //     this.startRecognition();
  //   }).catch((error)=>{
  //     console.log("error loading models",error);
  //   });
  // }

  async loadFaceApiModels(){
    await this.faceapiService.loadModels();
  }

  close() {
    this.closeModal.emit(true);
    this.stopCamera(false);
  }

  async startRecognition() {
    navigator.mediaDevices
    .getUserMedia({ video: true, audio: false })
    .then((stream) => {
      this.videoElement.nativeElement.srcObject = stream;
      this.analyzeFrame();
    })
    .catch((error) => {
      console.error('Error al acceder a la cámara:', error);
    });
  }

   // Activa la cámara y muestra el video en tiempo real
   async startRecognitionV2() {
    const hasPermission:string = await this.cameraPermissionService.checkCameraPermission();
    if(hasPermission == PermissionStatus.error || hasPermission == PermissionStatus.unsupported){
      this.errorType = RecognitionErrors.Camera;
      this.recognitionStep = RecognitionSteps.RecognitionFailed;
      return;
    }else if(hasPermission !== PermissionStatus.granted){
      const accepted : boolean = await this.cameraPermissionService.requestCameraAccess();
      if(!accepted){
        this.errorType = RecognitionErrors.Permissions;
        this.recognitionStep = RecognitionSteps.RecognitionFailed;
        return;
      }
    }

    this.recognitionStep = this.recognitionSteps.Recognizing;
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
    .then((stream) => {
      this.videoElement.nativeElement.srcObject = stream;
      this.analyzeFrame();
    })
    .catch((error) => {
      this.errorType = RecognitionErrors.Camera;
      this.recognitionStep = RecognitionSteps.RecognitionFailed;
      console.error('Error al acceder a la cámara:', error);
    });
  }

  async analyzeFrame() {
    const totalSeconds = 3;
    this.videoElement.nativeElement.addEventListener('play', async () => {
      setTimeout(() => {
        this.capturePhoto();
      }, 3000);
    });
    this.videoElement.nativeElement.play();
    this.startScanSimulation();
  }

  async analyzeFrameV2() {
    const totalSeconds = 3;
    const detectionsPerSecond = 3;
    this.videoElement.nativeElement.addEventListener('play', async () => {
      this.startScanSimulation();
      const displaySize = {
        width: 200,
        height: 200
      };
      faceapi.matchDimensions(this.canvasElement.nativeElement, displaySize);
      let firstIteraction:boolean = true;
    
      this.scanInterval = setInterval(async () => {
        // Detecciones del rostro
        const detection = await this.faceapiService.detectFace(this.videoElement.nativeElement);
        if(detection){
          // Agrega el descriptor al arreglo
          this.descriptors.push(detection.descriptor);
          // Captura la imagen asociada si aún no tenemos una o este descriptor es el mejor
          if(this.validateDetection(detection)){
            if (!this.bestDescriptor || this.isDescriptorBetter(detection.descriptor, this.bestDescriptor)) {
              this.bestDescriptor = detection.descriptor;
              this.capturePhoto();
            }
          }

          if(firstIteraction){
            firstIteraction = false;
            setTimeout(() => {
              clearInterval(this.scanInterval);
              // Promedia los descriptores o usa el mejor
              this.averageDescriptor = this.averageDescriptors(this.descriptors);
              this.stopCamera(true);
            }, totalSeconds * 1000); // Detén el proceso tras X segundos
          }
        }
      }, 1000 / detectionsPerSecond);
    });

    this.videoElement.nativeElement.play();
  }

  startScanSimulation() {
    this.scanInterval = setInterval(() => {
      this.activeScanLines++; // Incrementa el número de líneas activas

      if (this.activeScanLines >= this.scanLines.length) {
        clearInterval(this.scanInterval);
      }
    }, 60);
  }

  // MANEJO DE LOS DESCRIPTORES >>>>>>>>>
  validateDetection(detection: any): boolean {
    // Confianza mínima del 95%
    const isHighConfidence = detection.detection.score >= 0.85; //(0.95) para mas exactitud
  
    // Coordenadas del centro del rostro
    const centerX = detection.detection.box.x + detection.detection.box.width / 2;
    const centerY = detection.detection.box.y + detection.detection.box.height / 2;
  
    // Coordenadas del centro del video
    const videoCenterX = this.videoElement.nativeElement.videoWidth / 2;
    const videoCenterY = this.videoElement.nativeElement.videoHeight / 2;
  
    // Tolerancia de centrado (20% del tamaño del video)
    const toleranceX = this.videoElement.nativeElement.videoWidth * 0.2;
    const toleranceY = this.videoElement.nativeElement.videoHeight * 0.2;
  
    const isCentered = Math.abs(centerX - videoCenterX) <= toleranceX &&
                       Math.abs(centerY - videoCenterY) <= toleranceY;
  
    // Retorna true si ambas condiciones se cumplen
    return isHighConfidence && isCentered;
  }

  averageDescriptors(descriptors: Float32Array[]): Float32Array {
    if (descriptors.length === 0) return new Float32Array(128); // Devuelve un descriptor vacío si no hay datos
  
    const sum = descriptors.reduce((acc, desc) => {
      desc.forEach((value, index) => {
        acc[index] += value;
      });
      return acc;
    }, new Float32Array(128));
  
    return sum.map((value) => value / descriptors.length); // Divide cada valor entre la cantidad total de descriptores
  }

  isDescriptorBetter(descriptor: Float32Array, currentBest: Float32Array): boolean {
    const distanceToCurrentBest = this.calculateEuclideanDistance(descriptor, currentBest);
    return distanceToCurrentBest < 0.85; // Ajustable
  }

  calculateEuclideanDistance(vec1: Float32Array, vec2: Float32Array): number {
    return Math.sqrt(vec1.reduce((sum, value, index) => sum + Math.pow(value - vec2[index], 2), 0));
  }
  // MANEJO DE LOS DESCRIPTORES <<<<<<<<<<<<

  capturePhotoV2() {
    const video = this.videoElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');

    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageDataUrl = canvas.toDataURL('image/png');
      this.bestImage = imageDataUrl;
      context?.clearRect(0, 0, this.canvasElement.nativeElement.width, this.canvasElement.nativeElement.height);
    }
  }

  
  capturePhoto() {
    const video = this.videoElement.nativeElement;
    const canvas = this.canvasElement.nativeElement;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageDataUrl = canvas.toDataURL('image/png');

      this.urlPhoto.emit(imageDataUrl);
      this.stopCamera(true);
      // this.bestImage = imageDataUrl;
      context?.clearRect(0, 0, this.canvasElement.nativeElement.width, this.canvasElement.nativeElement.height);
    }
  }

  // Función para convertir Base64 a Blob
  dataURLtoBlob(dataUrl: string): Blob {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  stopCamera(captured: boolean) {
    const stream = this.videoElement.nativeElement.srcObject as MediaStream;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop());
    // if(captured){
    //   this.bestImageBlob = this.dataURLtoBlob(this.bestImage);
    //   this.faceData.emit({bestImageBlob: this.bestImageBlob, bestImage: this.bestImage, bestDescriptor: this.bestDescriptor, averageDescriptor: this.averageDescriptor});
    // }

    this.activeScanLines = 0;
    this.photoCaptured = false;
    this.photoControl.reset();
    this.recognitionStep = this.recognitionSteps.PreviewRecognition;
    clearInterval(this.scanInterval);
  }
}
