import { HttpParams } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import { OutputFilterDatepicker } from 'src/app/common-components/datepicker/datepicker.component';
import { Pagination } from 'src/app/interface/accountancy.interface';
import { Unit } from 'src/app/interface/administrative';
import { Building, BuildingConfig, Timezome } from 'src/app/interface/buildings.interface';
import { Emergency, EmergencyStatus } from 'src/app/interface/emergency.interface';
import { User, UserRoles } from 'src/app/interface/users.interface';
import { ApartmentService } from 'src/app/services/apartment.service';
import { ChatV2Service } from 'src/app/services/chatv2.service';
import { EmergencyService } from 'src/app/services/emergency.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-emergency-table',
  templateUrl: './emergency-table.component.html',
  styleUrls: ['./emergency-table.component.scss']
})
export class EmergencyTableComponent implements OnInit, AfterViewInit {
  @ViewChild('emergencyDetailModal', { static: true }) emergencyDetailModal: ElementRef;
  @ViewChild('table') table!: DatatableComponent;

  listEmergencyStatus = [{ label: '', value: '' }];

  placeHolderRangeStart: string;
  placeHolderRangeEnd: string;

  listUnits: Unit[] = [];
  listUsers: User[] = [];
  emergencyStatusView = [EmergencyStatus.attended, EmergencyStatus.falseAlarm, EmergencyStatus.canceled, EmergencyStatus.noCompleted];

  disabled: boolean = false;

  pagination: Pagination = { page: 1, size: 10 };
  pageSizeOptions: number[] = [10, 25, 50, 100];

  totalDocs: number = 0;
  columns: any[] = [{ prop: 'user' }, { prop: 'unit' }, { prop: 'lastStatusAt' }, { prop: 'responseTime' }, { prop: 'reason' }, { prop: 'status' }];
  accessData: Emergency[] = [];
  showUp728 = true;
  showUp1024 = true;
  showUp640 = true;
  showUp992 = true;
  showUp576 = true;

  params: HttpParams = new HttpParams().set('page', this.pagination.page)

  emergencyDetail: Emergency;

  modalRef: NgbModalRef;

  @Input() isSuperAdmin?: boolean = false;
  @Input() building?: Building;
  @Input() buildingConfig?: BuildingConfig;

  isChatEnableBuilding: boolean = false;
  rolesChatBuilding: UserRoles[] = [];
  roomId?: string;
  timeZone: string = Timezome.panama;

  constructor(
    private emergencyService: EmergencyService,
    private apartmentService: ApartmentService,
    private chatV2Service: ChatV2Service,
    private modalService: NgbModal,
    private userService: UserService,
    private router: Router,
    private cdr: ChangeDetectorRef) {
    localStorage.removeItem('tempUserChat');
    const timeZone = this.emergencyService.getBuildingTimeZone();
    if (timeZone) this.timeZone = timeZone;
  }
  ngAfterViewInit(): void {
    this.resizeTable();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.listEmergencyStatus = Object.values(this.emergencyStatusView).map(c => ({ label: this.emergencyService.translate.instant('emergency.status.' + c), value: c }));
    }, 900);

    const params = new HttpParams().set('page', 1).set('rowsPerPage', 50);

    if (this.isSuperAdmin && this.building.id) {
      this.timeZone = this.buildingConfig.timezone;
      this.getSuperEmergencies(this.building.id, params);
      this.getSuperUnitAll(this.building.id, params)
      this.getSuperUsersByBuilding(this.building.id, params);
    } else {
      this.isChatEnableBuilding = this.emergencyService.isChatEnable();
      if (this.isChatEnableBuilding) this.rolesChatBuilding = this.emergencyService.getChatRoles();

      this.getEmergencies(this.params);
      this.getUnitAll(params)
      this.getUsersByBuilding(params);
    }

    this.adjustColumns(window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.adjustColumns(event.target.innerWidth);
  }

  getEmergencies(params: HttpParams) {
    this.emergencyService.setLoading(true);
    this.emergencyService.getEmergencies(params).subscribe({
      next: (value) => {
        this.totalDocs = value.data.totalDocs;
        this.accessData = value.data.docs;
        this.emergencyService.setLoading(false);
      },
      error: (error) => {
        this.emergencyService.toastr.error(this.emergencyService.translate.instant('emergency.errors.query'))
        this.emergencyService.setLoading(false);
      }
    })
  }

  getSuperEmergencies(buildingId: string, params: HttpParams) {
    this.emergencyService.setLoading(true);
    this.emergencyService.getSuperEmergenciesByBuildingId(buildingId, params).subscribe({
      next: (value) => {
        this.totalDocs = value.data.totalDocs;
        this.accessData = value.data.docs;
        this.emergencyService.setLoading(false);
      },
      error: (error) => {
        this.emergencyService.toastr.error(this.emergencyService.translate.instant('emergency.errors.query'))
        this.emergencyService.setLoading(false);
      }
    })
  }

  setSize() {
    this.pagination.page = 1;
    this.params = this.params.set('page', this.pagination.page);
    this.params = this.params.set('size', this.pagination.size);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }

    this.getEmergencies(this.params)
  }

  setPage(event: any) {
    this.pagination.page = event.page;
    this.pagination.page = this.pagination.page;
    this.pagination.size = this.pagination.size;

    this.params = this.params.set('page', this.pagination.page);
    this.params = this.params.set('size', this.pagination.size);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }

    this.getEmergencies(this.params)
  }

  openEmergencyDetails(emergency: Emergency) {
    this.emergencyDetail = emergency;
    if (this.roomId) this.emergencyDetail.roomId = this.roomId;
    this.modalRef = this.modalService.open(this.emergencyDetailModal, {
      windowClass: 'emergency__modal-detail',
      backdropClass: 'blur-backdrop',
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    });
  }

  getEmergencyById(emergency: Emergency) {
    this.emergencyService.setLoading(true);
    this.emergencyService.getEmergenciById(emergency.id).subscribe({
      next: (value) => {
        this.openEmergencyDetails({ userRoles: emergency.userRoles, ...value.data });
        this.emergencyService.setLoading(false);
      },
      error: (error) => {
        this.emergencyService.toastr.error(this.emergencyService.translate.instant('emergency.errors.emergencyDetail'))
        this.emergencyService.setLoading(false);
      }
    })
  }

  closeModal(): void {
    this.modalRef.close();
  }

  getUnitAll(params: HttpParams) {
    this.emergencyService.setLoading(true);
    this.apartmentService.getAllUnits(params).subscribe({
      next: (value: any) => {
        this.listUnits = value.data.units.docs;
        this.emergencyService.setLoading(false);
      },
      error: (error) => {
        this.emergencyService.toastr.error(this.emergencyService.translate.instant('emergency.errors.units'));
        this.emergencyService.setLoading(false);
      }
    }
    );
  }

  getSuperUnitAll(buildingId: string, params: HttpParams) {
    this.emergencyService.setLoading(true);
    this.apartmentService.getSuperAllUnits(buildingId, params).subscribe({
      next: (value: any) => {
        this.listUnits = value.data.units.docs;
        this.emergencyService.setLoading(false);
      },
      error: (error) => {
        this.emergencyService.toastr.error(this.emergencyService.translate.instant('emergency.errors.units'));
        this.emergencyService.setLoading(false);
      }
    }
    );
  }

  getUsersByBuilding(params: HttpParams) {
    this.userService.getAllUsers(params).subscribe(
      (response: any) => {
        this.listUsers = response.data.docs;
      },
      (error) => {
        this.emergencyService.toastr.error(this.emergencyService.translate.instant('emergency.errors.users'));
        this.emergencyService.setLoading(false);
      },
    );
  }

  getSuperUsersByBuilding(buildingId: string, params: HttpParams) {
    params = params.set('buildingId', buildingId);
    this.userService.getSuperAllUsers(params).subscribe(
      (response: any) => {
        this.listUsers = response.data.docs;
      },
      (error) => {
        this.emergencyService.toastr.error(this.emergencyService.translate.instant('emergency.errors.users'));
        this.emergencyService.setLoading(false);
      },
    );
  }

  formatDuration(row: Emergency) {
    const assistedBy = row.statusHistory.find(s => s.status === EmergencyStatus.vigilantOnWay);

    if (!assistedBy) return '--';

    return this.emergencyService.formatDurationDateToSeconds(assistedBy.duration);
  }

  filterTypeSearchUnit(data?: string) {
    let params = new HttpParams().set('page', 1).set('rowsPerPage', 50);

    if (data) params = params.set('search', data);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperUnitAll(this.building.id, params)
      return;
    }
    this.getUnitAll(params);
  }

  filterTypeSelectUnit(data?: string) {
    this.params = this.params.delete('unitId');
    this.pagination.page = 1;
    this.params = this.params.set('page', this.pagination.page);


    if (data) this.params = this.params.set('unitId', data);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }

    this.getEmergencies(this.params)
  }

  filterTypeSearchUser(data?: string) {
    let params = new HttpParams().set('page', 1).set('rowsPerPage', 50);

    if (data) params = params.set('name', data);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperUsersByBuilding(this.building.id, this.params)
      return;
    }

    this.getUsersByBuilding(params);
  }

  filterTypeSelectUser(data?: string) {
    this.params = this.params.delete('userId');
    this.pagination.page = 1;
    this.params = this.params.set('page', this.pagination.page);

    if (data) this.params = this.params.set('userId', data);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }

    this.getEmergencies(this.params)
  }

  filterTypeSelectStatus(data?: string) {
    this.params = this.params.delete('status');
    this.pagination.page = 1;
    this.params = this.params.set('page', this.pagination.page);

    if (data) this.params = this.params.set('status', data);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }

    this.getEmergencies(this.params)
  }

  onClearUnit(payload: boolean) {
    this.params = this.params.delete('unitId');
    this.pagination.page = 1;
    this.params = this.params.set('page', this.pagination.page);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }

    this.getEmergencies(this.params);
  }

  onClearUser(payload: boolean) {
    this.params = this.params.delete('userId');
    this.pagination.page = 1;
    this.params = this.params.set('page', this.pagination.page);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }

    this.getEmergencies(this.params);
  }

  onClearStatus(payload: boolean) {
    this.params = this.params.delete('status');
    this.pagination.page = 1;
    this.params = this.params.set('page', this.pagination.page);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }

    this.getEmergencies(this.params);
  }

  onClearDatapicker(payload: boolean) {
    this.params = this.params.delete('startDate');
    this.params = this.params.delete('endDate');

    this.pagination.page = 1;
    this.params = this.params.set('page', this.pagination.page);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }

    this.getEmergencies(this.params);
  }

  valueFnUnit(option?: any): any {
    return option ? option.id : option.value;
  }

  displayFnUnit(option?: any): string {
    return option ? option.number : option.label;
  }

  valueFnUser(option?: any): any {
    return option ? option.id : option.value;
  }

  displayFnUser(option?: any): string {
    return option ? option.name : option.label;
  }

  displayFnStatus(option: any): string {
    return option.label;
  }

  valueFnStatus(option: any): any {
    return option.value;
  }

  filterTypeDateRange(payload: OutputFilterDatepicker) {
    if (!payload.startDate && !payload.endDate) {
      this.emergencyService.toastr.info(this.emergencyService.translate.instant('emergency.errors.startDateAndEndDateRequired'))
      return;
    }

    const startDate = moment(payload.startDate);
    const endDate = moment(payload.endDate);

    const diffInMonths = endDate.diff(startDate, 'months', true);
    if (diffInMonths > 3) {
      this.emergencyService.toastr.info(this.emergencyService.translate.instant('emergency.errors.interval3Months'))
      return;
    }

    this.params = this.params.set('startDate', payload.startDate);
    this.params = this.params.set('endDate', payload.endDate);
    this.pagination.page = 1;
    this.params = this.params.set('page', this.pagination.page);

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperEmergencies(this.building.id, this.params)
      return;
    }


    this.getEmergencies(this.params);
  }

  donwloadEmergencies(payload: boolean) {
    const params: any = {};

    if (this.params.has('startDate') && this.params.has('endDate')) {
      params.startDate = this.params.get('startDate');
      params.endDate = this.params.get('endDate');
    }

    if (this.params.has('unitId')) {
      params.unitId = this.params.get('unitId')
    }

    if (this.params.has('userId')) {
      params.userId = this.params.get('userId')
    }

    if (this.params.has('status')) {
      params.status = this.params.get('status')
    }

    if (this.isSuperAdmin && this.building.id) {
      this.getSuperDonwloadFileExcel(this.params)
      return;
    }

    this.getDonwloadFileExcel(params);
  }

  getDonwloadFileExcel(params: HttpParams) {
    this.emergencyService.setLoading(true);
    this.emergencyService.getDonwloadEmergencies(params).subscribe({
      next: (value) => {
        this.emergencyService.toastr.success(this.emergencyService.translate.instant('emergency.success.generating'))
        this.emergencyService.setLoading(false);
      },
      error: (error) => {
        this.emergencyService.toastr.error(this.emergencyService.translate.instant('emergency.errors.generationError'))
        this.emergencyService.setLoading(false);
      },
    })
  }

  getSuperDonwloadFileExcel(params: HttpParams) {
    this.emergencyService.setLoading(true);
    this.emergencyService.getSuperDonwloadEmergenciesBuildingId(this.building.id, params).subscribe({
      next: (value) => {
        this.emergencyService.toastr.success(this.emergencyService.translate.instant('emergency.success.generating'))
        this.emergencyService.setLoading(false);
      },
      error: (error) => {
        this.emergencyService.toastr.error(this.emergencyService.translate.instant('emergency.errors.generationError'))
        this.emergencyService.setLoading(false);
      },
    })
  }

  private adjustColumns(width: number): void {
    this.showUp1024 = width > 1024;
    this.showUp728 = width > 768;
    this.showUp992 = width > 992;
    this.showUp640 = width > 640;
    this.showUp576 = width > 576;
  }

  onEventChat(emergency: Emergency) {
    this.chatV2Service.getRoomWith(emergency.user.id).subscribe({
      next: (value: any) => {
        if (!value) {
          this.emergencyService.toastr.info(this.emergencyService.translate.instant('emergency.errors.loadChat'));
          return;
        }

        this.roomId = value.data?.room;

        if (!this.roomId) {
          localStorage.setItem('tempUserChat', JSON.stringify(value.data.user));
          this.roomId = value.data.user.id;
        }

        this.router.navigate([`/app/messages/${this.roomId}`])
      },
      error: (err) => {
        this.emergencyService.toastr.info(this.emergencyService.translate.instant('emergency.errors.loadChat'));
      },
    })
  }

  resizeTable(): void {
    const sideNavRail = document.getElementById('sideNavRail') as HTMLElement;
    const resizeObserver = new ResizeObserver(
      debounce(() => {
        this.table?.recalculate();
        this.cdr.detectChanges();
      }, 50));
    resizeObserver.observe(sideNavRail);
  }

  formatDate(date: string, format: string): string {
    return moment(date).tz(this.timeZone).format(format);
  }
}
