import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { SecurityStateDTO, AccessEntryStatus, typeVehicle, AccessType } from '../../../../interface/frequentAccess.inteface';
import { isEmpty } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DAYS_GLOBALS } from 'src/app/interface/data.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-security-admin-detail',
  templateUrl: './security-admin-detail.component.html',
  styleUrls: ['./security-admin-detail.component.scss']
})
export class SecurityAdminDetailComponent implements OnInit {
  modalRef: any;
  isExtensionOption: boolean = false;
  frequentAccessId: string = "";
  invitationId: string = "";
  hourAccess: String | Date;
  @Input() accessSelectedInfo: any;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @ViewChild('extendVisit', { static: true }) extendVisit: ElementRef;

  accessSelected: SecurityStateDTO;
  typeVehicleToShow: string = "";
  validVehicle: boolean = false;
  days: string[] = DAYS_GLOBALS;
  isBusiness: boolean = false;
  isAccessFrequent: boolean = false;
  accesTypeTitle: string = 'tenant';

  listStatesAccessEntry = {
    [AccessEntryStatus.active]: 'frequentAccess.securityCenter.statusEntry.active',
    [AccessEntryStatus.displacement]: 'frequentAccess.securityCenter.statusEntry.displacement',
    [AccessEntryStatus.timeExceeded]: 'frequentAccess.securityCenter.statusEntry.timeExceeded',
  };

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.addSvgIcon('badge', 'assets/images/icons/icons-outline/Icon_action_badge.svg');
    this.addSvgIcon('call_end', 'assets/images/icons/icons-outline/Icon_comunication_call_end.svg');
    this.addSvgIcon('mail', 'assets/images/icons/icons-outline/Icon_comunication_mail.svg');
    this.addSvgIcon('garage_door', 'assets/images/icons/icons-outline/garage_door.svg');
    this.addSvgIcon('notifications_group', 'assets/images/icons/icons-outline/Icon_notifications_group.svg');
    this.addSvgIcon('date_range', 'assets/images/icons/icons-outline/Icon_action_date_range.svg');
    this.addSvgIcon('transportation', 'assets/images/icons/icons-outline/transportation.svg');
    this.addSvgIcon('garage', 'assets/images/icons/icons-outline/garage.svg');
    this.addSvgIcon('traffic_jam', 'assets/images/icons/icons-outline/traffic_jam.svg');
    this.addSvgIcon('car_crash', 'assets/images/icons/icons-outline/car_crash.svg');
    this.addSvgIcon('car', 'assets/images/icons/icons-outline/Icon_maps_directions_car.svg');
    this.addSvgIcon('edit', 'assets/images/icons/icons-outline/Icon_blue_edit_edit.svg');
    this.addSvgIcon('shared', 'assets/images/icons/icons-outline/Icon_share.svg');
    this.addSvgIcon('info', 'assets/images/icons/icons-outline/Icon_info-C93749.svg');
    this.addSvgIcon('factory', 'assets/images/icons/icons-outline/Icon_factory.svg');
  }
  ngOnInit(): void {
    this.isBusiness = this.router.url.includes('business');
    if (this.accessSelectedInfo.vehicle && Object.keys(this.accessSelectedInfo.vehicle).length > 0) {
      this.validVehicle = true;
    }
    this.accessSelected = this.accessSelectedInfo;

    if (this.accessSelected.frequentAccessId && this.accessSelected.frequentAccessId['accessType'] === AccessType.unique && this.accessSelected.accessEntryStatus == AccessEntryStatus.active) {
      this.hourAccess = this.accessSelected.date;
      this.invitationId = this.accessSelected.invitationId;
      this.frequentAccessId = this.accessSelected.frequentAccessId['id'];
      this.isExtensionOption = !this.isExtensionOption;
    }
    if (this.accessSelected?.accessEntryStatus) this.accessSelected.accessEntryStatus = this.changeStatusLabel(this.accessSelected.accessEntryStatus);
    this.validateTypeAccessFrequent()
  }

  setStyleCardProgressBar(access: SecurityStateDTO) {
    if (access.accessEntry[2].status == AccessEntryStatus.timeExceeded) {
      return true;
    }
    return false;
  }

  setStyleProgressBar(status: string, checked: boolean) {
    if (!status && checked) return "";
    let accessStyle: string = "";
    switch (status) {
      case AccessEntryStatus.active:
        if (!checked) {
          accessStyle = "";
        }
        break;
      case AccessEntryStatus.displacement:
        accessStyle = AccessEntryStatus.displacement;
        break;
      case AccessEntryStatus.completed:
        if (checked) {
          accessStyle = AccessEntryStatus.completed;
        }
        break;
      case AccessEntryStatus.timeExceeded:
        accessStyle = AccessEntryStatus.timeExceeded;
        break;
      default:
        break;
    }
    return accessStyle;
  }

  openExtendVisit(): void {
    let ref = this.extendVisit;
    this.modalRef = this.modalService.open(ref, {
      windowClass: 'extend-visit-modal',
      backdropClass: 'blur-backdrop',
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    })
  }

  close(): void {
    this.cancel.emit();
  }

  closeExtendVisit(e?: any): void {
    this.modalRef.close();
    if (e) this.close();
  }

  addSvgIcon(name: string, path: string) {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }

  changeStatusLabel(status: AccessEntryStatus) {
    switch (status) {
      case AccessEntryStatus.completed:
        return AccessEntryStatus.left;
      case AccessEntryStatus.active:
        return AccessEntryStatus.entered;
      default:
        return status;
    }
  }

  validateTypeAccessFrequent() {
    const { frequentAccessId } = this.accessSelected;

    if (frequentAccessId && (frequentAccessId['accessType'] === 'unique' || frequentAccessId['accessType'] === 'frequent' || frequentAccessId['accessType'] === 'tenant') && frequentAccessId['schedule']) {
      this.isAccessFrequent = true

      this.accesTypeTitle = this.accessSelected.frequentAccessId['accessType'];

      if (this.accessSelected.frequentAccessId['accessType'] === 'tenant') this.accesTypeTitle = AccessType.frequent;
    }
  }
}
