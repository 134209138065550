import { Pipe, PipeTransform } from '@angular/core';
import { UserRoles } from '../interface/users.interface';

@Pipe({
  name: 'hasUserRole',
  pure: true,
})
export class HasUserRolePipe implements PipeTransform {
  transform(userRoles: UserRoles[], chatBuildingRoles: UserRoles[]): boolean {
    return userRoles.some(role => chatBuildingRoles.includes(role));
  }
}