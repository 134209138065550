import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { FloatLabelType } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

export type typeSearch = 'local' | 'service';

@Component({
  selector: 'select-outline',
  templateUrl: './select-outline.component.html',
  styleUrls: ['./select-outline.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectOutlineComponent implements OnInit, OnChanges {
  @ViewChild('inputRef') inputRef!: ElementRef<HTMLInputElement>;

  control = new FormControl('');

  @Input() options: any[] = [];
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() floatLabelType?: FloatLabelType = 'never';
  @Input() disabled: boolean;
  @Input() typeSearch: typeSearch;
  @Input() displayFn?: (option: any) => any;
  @Input() valueFn?: (option: any) => any;

  @Output() outputSelect: EventEmitter<string> = new EventEmitter();
  @Output() outputSearch: EventEmitter<string> = new EventEmitter();
  @Output() outputClear: EventEmitter<boolean> = new EventEmitter();

  isOpenPanel: boolean = true;
  filteredOptions: Observable<any[]>;

  constructor() { }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.options.length > 0) this.observerChangeSearchForm();
  }

  ngOnInit(): void {
    this.displayFn = this.displayFn || ((option: { label: string; value: string }) => option.label);
    this.valueFn = this.valueFn || ((option: { label: string; value: string }) => option.value);

    if (this.disabled) {
      this.control.disable()
      return;
    }
  }

  onSelect(value: string) {
    this.outputSelect.emit(this.valueFn(value));
    this.blurInput();
  }

  onClear(): void {
    this.control.reset('', { emitEvent: false });
    this.outputClear.emit(true);
    this.observerChangeSearchForm();
    this.blurInput();
  }

  openOrClosePanel(event: Event, triggerC: MatAutocompleteTrigger): void {
    event.stopPropagation();

    this.isOpenPanel ? triggerC.openPanel() : triggerC.closePanel();
    this.isOpenPanel = !this.isOpenPanel;
  }

  blurInput(): void {
    this.inputRef.nativeElement.blur();
  }

  observerChangeSearchForm() {
    this.filteredOptions = this.control.valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged(),
        map(value => this._filter(value))
      )
  }

  private _filter(value?: string): string[] {
    if (!value) return this.options;

    const filterValue = value.toLowerCase();

    const exist = this.options.filter(option => this.displayFn(option).toLowerCase().includes(filterValue));

    if (this.typeSearch === 'service' && filterValue && exist.length === 0) {
      this.outputSearch.emit(filterValue);
      return [];
    }

    return exist
  }
}
