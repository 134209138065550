<section class="container__emergency subheader">
  <div class="container__title" *ngIf="!isSuperAdmin">
    <h5 class="h5_700">{{'menu.emergency' | translate}}</h5>
  </div>
  <div class="container__title" *ngIf="isSuperAdmin">
    <h5 class="h5_700">{{'emergency.super.emergencyOf' | translate}} {{ building.name}}</h5>
  </div>
  <div class="container__emergency-filters">
    <div class="container__emergency-filters-left">
      <select-outline [placeholder]="'emergency.filters.user'| translate" [floatLabelType]="'never'"
        [disabled]="disabled" [options]="listUsers" [typeSearch]="'service'"
        (outputSearch)="filterTypeSearchUser($event)" (outputSelect)="filterTypeSelectUser($event)"
        (outputClear)="onClearUser($event)" [valueFn]="valueFnUser" [displayFn]="displayFnUser"></select-outline>
      <select-outline [placeholder]="'emergency.filters.unit'| translate" [floatLabelType]="'never'"
        [disabled]="disabled" [options]="listUnits" [typeSearch]="'service'"
        (outputSearch)="filterTypeSearchUnit($event)" (outputSelect)="filterTypeSelectUnit($event)"
        (outputClear)="onClearUnit($event)" [valueFn]="valueFnUnit" [displayFn]="displayFnUnit"></select-outline>
      <select-outline [placeholder]="'emergency.filters.status'| translate" [floatLabelType]="'never'"
        [disabled]="disabled" [options]="listEmergencyStatus" (outputSelect)="filterTypeSelectStatus($event)"
        (outputClear)="onClearStatus($event)"></select-outline>

      <datepicker-library [placeholderStart]="'emergency.filters.startDate'| translate"
        [placeholderEnd]="'emergency.filters.endDate'| translate" (outputFilter)="filterTypeDateRange($event)"
        (clear)="onClearDatapicker($event)"></datepicker-library>
    </div>

    <div class="container__emergency-filters-rigth">
      <common-buttons-outlined [label]="'emergency.filters.donwload' | translate" [disabled]="false"
        [iconPosition]="false" [icon]="'download'" [typeIcon]="'outlined'"
        (output)="donwloadEmergencies($event)"></common-buttons-outlined>
    </div>
  </div>
</section>
<section class="container__emergency-table">

  <div class="container-fluid">
    <!-- TABLE VERSION -->
    <ngx-datatable #table class="material fluid-row-ngx-table" [headerHeight]="56" [footerHeight]="52" [rowHeight]="52"
      [loadingIndicator]="false" [columnMode]="'force'" [externalPaging]="true" [columns]="columns" [rows]="accessData"
      [limit]="pagination.size" [count]="totalDocs" [offset]="pagination.page - 1" [reorderable]="false"
      [swapColumns]="false" [scrollbarH]="false"
      [messages]="{ emptyMessage: ( 'emergency.table.nodata' | translate ) }">

      <!-- User -->
      <ngx-datatable-column *ngIf="showUp1024 || showUp992" [sortable]="false" prop="user" [resizeable]="false"
        name="{{ 'emergency.table.user' | translate }}" headerClass="padding-left-none"
        cellClass="left-align">
        <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
          <div class="user-cell" container="body" placement="auto" [ngbTooltip]="row.user.name">
            <img class="user-photo" width="32" height="32" [src]="row.user.photoUrl">
            <span>{{ row.user.name.length > 20 ? (row.user.name | slice:0:20) + '...' : row.user.name }}</span>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- Unit -->
      <ngx-datatable-column *ngIf="showUp1024 || showUp992 || showUp728" [sortable]="false" prop="unit"
        [resizeable]="false" name="{{ 'emergency.table.unit' | translate }}" headerClass="padding-left-none"
        cellClass="left-align">
        <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
          <div container="body" placement="auto" [ngbTooltip]="row?.unit?.number || ''">
            {{ row?.unit?.number?.length > 20 ? (row.unit.number | slice:0:20) + '...' : row.unit.number }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- Date emegergency -->
      <ngx-datatable-column [sortable]="false" prop="lastStatusAt" [resizeable]="false"
        [name]="!showUp728 ? ('emergency.table.lastStatusAtShort' | translate) : ('emergency.table.lastStatusAt' | translate)"
        headerClass="padding-left-none custom__wrap" cellClass="left-align">
        <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
          {{ showUp992 ? formatDate(row.lastStatusAt, 'DD MMM yyyy h:mm a') : formatDate(row.lastStatusAt, 'DD MMM h:mm a') }}
        </ng-template>
      </ngx-datatable-column>

      <!-- Date responseTime -->
      <ngx-datatable-column *ngIf="showUp1024 || showUp992 " [sortable]="false" prop="responseTime" [resizeable]="false"
        name="{{ 'emergency.table.responseTime' | translate }}" headerClass="padding-left-none custom__wrap"
        cellClass="left-align">
        <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
          {{ formatDuration(row) }}
        </ng-template>
      </ngx-datatable-column>

      <!-- Reason -->
      <ngx-datatable-column *ngIf="showUp1024" [sortable]="false" prop="reason" [resizeable]="false"
        name="{{ 'emergency.table.reason' | translate }}" headerClass="padding-left-none" cellClass="left-align">
        <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
          {{ row?.reason ? (row.reason.length > 24 ? (row.reason | slice:0:24) + '...' : row.reason) : '--' }}
        </ng-template>
      </ngx-datatable-column>

      <!-- Status -->
      <ngx-datatable-column *ngIf="showUp1024 || showUp992 || showUp728 || showUp576" [sortable]="false" prop="status"
        [resizeable]="false" name="{{ 'emergency.table.status' | translate }}" headerClass="padding-left-none"
        cellClass="left-align" [minWidth]="150">
        <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
          <div class="custom__status" [class]="row.status">
            {{ 'emergency.status.' + row.status | translate | uppercase }}
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- Actions Column -->
      <ngx-datatable-column [sortable]="false" prop="actions" name="{{ 'emergency.table.actions' | translate }}">
        <ng-template let-value="value" ngx-datatable-cell-template let-row="row">
          <div [class]="'buttons'">
            <button class="btn-action"
              [disabled]="isSuperAdmin || (isChatEnableBuilding && !(row.userRoles | hasUserRole: rolesChatBuilding)) || (!isChatEnableBuilding && !(row.userRoles | hasUserRole: rolesChatBuilding))"
              (click)="onEventChat(row)">
              <mat-icon fontSet="material-icons-outlined">chat</mat-icon>
            </button>
            <button class="btn-action" (click)="getEmergencyById(row)">
              <mat-icon fontSet="material-icons-outlined">visibility</mat-icon>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>

      <!-- Footer Template -->
      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template>
          <div class="custom-footer">
            <!-- Div 1: Registros Totales -->
            <div class="footer-item">
              {{(showUp728 ? 'table.totalMessage': 'table.total') | translate}}: <strong class="ml-2">{{ totalDocs
                }}</strong>
            </div>

            <!-- Div 2: Registros por Página -->
            <div class="footer-item">
              <div *ngIf="showUp728">{{ 'table.recordsPerPage' | translate}}:</div>
              <select class="form-control" [(ngModel)]="pagination.size" (change)="setSize()">
                <option *ngFor="let opt of pageSizeOptions" [value]="opt">{{ opt }}</option>
              </select>
            </div>

            <!-- Div 3: Paginado -->
            <div class="footer-item paginator">
              <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'" [page]="pagination.page" [size]="pagination.size"
                [count]="totalDocs" [hidden]="!((totalDocs / pagination.size) > 1)" (change)="setPage($event)">
              </datatable-pager>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>
    <!-- TABLE VERSION -->
  </div>
</section>

<ng-template #emergencyDetailModal let-c="close" let-d="dismiss">
  <app-emergency-modal [emergency]="emergencyDetail" [buildingConfig]="buildingConfig" (close)="closeModal()"
    [isSuperAdmin]="isSuperAdmin"></app-emergency-modal>
</ng-template>