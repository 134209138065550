import { Injectable } from '@angular/core';

export enum PermissionStatus {
  granted = 'granted',
  denied = 'denied',
  prompt = 'prompt',
  unsupported = 'unsupported',
  error = 'error'
}

@Injectable({
  providedIn: 'root'
})
export class CameraPermissionService {

  constructor() {}

  //Función para verificar permisos de la cámara
  async checkCameraPermission(): Promise<string> {
    if (!navigator.permissions) {
      console.warn('Permissions API no es compatible con este navegador.');
      return 'unsupported';
    }

    try {
      const permissionStatus = await navigator.permissions.query({ name: 'camera' as PermissionName });
      return permissionStatus.state; // 'granted', 'denied' o 'prompt'
    } catch (error) {
      console.error('Error verificando permisos:', error);
      return 'error';
    }
  }

  //Función para solicitar acceso a la cámara
  async requestCameraAccess(): Promise<boolean> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      // Detener el stream inmediatamente después de solicitarlo para liberar la cámara
      stream.getTracks().forEach(track => track.stop());
      return true
    } catch (error) {
      return false;
    }
  }
}
