import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Emergency, EmergencyStatus, StatusHistory } from 'src/app/interface/emergency.interface';
import { UserRoles } from 'src/app/interface/users.interface';
import { ChatV2Service } from 'src/app/services/chatv2.service';
import { EmergencyService } from 'src/app/services/emergency.service';
import moment from 'moment-timezone';
import { BuildingConfig, Timezome } from 'src/app/interface/buildings.interface';

@Component({
  selector: 'app-emergency-modal',
  templateUrl: './emergency-modal.component.html',
  styleUrls: ['./emergency-modal.component.scss']
})
export class EmergencyModalComponent implements OnInit {
  @Input() emergency: Emergency;
  @Input() isSuperAdmin: boolean = false;

  assistedBy: StatusHistory | null;
  formatDuration?: string;
  @Output() close: EventEmitter<boolean> = new EventEmitter();
  isChatEnableBuilding: boolean = false;
  rolesChatBuilding: UserRoles[] = [];
  chatEnable: boolean = false;
  timeZone: string = Timezome.panama;
  @Input() buildingConfig?: BuildingConfig;

  constructor(private router: Router, private emergencyService: EmergencyService, private chatV2Service: ChatV2Service) {
    const timeZone = this.emergencyService.getBuildingTimeZone();
    if (timeZone) this.timeZone = timeZone;
  }

  ngOnInit(): void {
    if (!this.isSuperAdmin) {
      this.isChatEnableBuilding = this.emergencyService.isChatEnable();
      if (this.isChatEnableBuilding) this.rolesChatBuilding = this.emergencyService.getChatRoles();
      this.chatEnable = this.emergency.userRoles.some(role => this.rolesChatBuilding.includes(role));
      this.timeZone = this.buildingConfig.timezone;
    }

    const statusHistory = this.emergency.statusHistory;
    this.assistedBy = statusHistory.find(s => s.status === EmergencyStatus.vigilantOnWay);
    if (this.assistedBy?.duration) this.formatDuration = this.emergencyService.formatDurationDateToSeconds(this.assistedBy.duration);
  }

  closeModal() {
    this.close.emit(true);
  }

  onEventButton(event: boolean) {
    this.chatV2Service.getRoomWith(this.emergency.user.id).subscribe({
      next: (value: any) => {
        if (!value) {
          this.emergencyService.toastr.info(this.emergencyService.translate.instant('emergency.errors.loadChat'));
          return;
        }

        let roomId = value.data?.room;

        if (!roomId) {
          localStorage.setItem('tempUserChat', JSON.stringify(value.data.user));
          roomId = value.data.user.id;
        }

        this.closeModal();
        this.router.navigate([`/app/messages/${roomId}`])
      },
      error: (err) => {
        this.emergencyService.toastr.info(this.emergencyService.translate.instant('emergency.errors.loadChat'));
        this.closeModal();
      },
    })
  }

  formatDate(date: string, timeZone: string, format: string): string {
    return moment(date).tz(timeZone).format(format);
  }
}
