import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { UserRoles } from './interface/users.interface';
import { DataService } from './services/data.service';
import { HomeComponentsService } from './services/home-components.service';

declare global {
  interface Window { intercomSettings: any; }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'munily-dashboard';
  message: any;
  loading: boolean = false;
  showModal: boolean = false;
  lang = new Subject<string>();
  lang$: Observable<any>;
  showModal$: Observable<any>;

  constructor(public dataService: DataService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private homeService: HomeComponentsService) {
    this.lang$ = this.dataService.getLoading();
  }

  ngOnInit() {
    this.lang.pipe(startWith(this.translate.getBrowserLang())).subscribe((lang) => {
      const appLang = this.dataService.getAppLanguage();
      this.dataService.setLanguage(appLang ? appLang : lang);
    });

    this.lang$.subscribe((state) => {
      setTimeout(() => {
        this.loading = state.isLoading;
      }, 200);
    });

    let user = this.dataService.getUser();
    const authorizedRole =  user.roles?.some((rol:string)=> [UserRoles.admin,UserRoles.customized].includes(UserRoles[rol]));
    if(user && user.roles && authorizedRole) this.refreshLocalStorageData();
  }

  refreshLocalStorageData(){
    this.homeService.getRefreshBuildingConfiguration().subscribe((data:any)=>{
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      const response:any = data.data;
      if(typeof(response.building) == 'string') {
        this.toastr.error(this.translate.instant('toastrMessage.error.refreshData'));
        return;
      }
      let building = response.building;
      building.roles = response.roles;
      building.userStatus = response.status;
      building.id = building._id ?? building.id;
      currentUser.buildings[0] = building;
      this.dataService.setUser(currentUser);
    },error=>{
      console.log(error);
      this.toastr.error(this.translate.instant('toastrMessage.error.refreshData'));
    });
  }
}
