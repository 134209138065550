<form [formGroup]="dateRangeForm">
  <mat-form-field appearance="outline" class="custom__class_datepicker">
    <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
      <input matStartDate [placeholder]="placeholderStart" formControlName="startDate" [autocomplete]="autocomplete" />
      <input matEndDate [placeholder]="placeholderEnd" formControlName="endDate" (dateChange)="selectDateRange()"
        [autocomplete]="autocomplete" />
    </mat-date-range-input>

    <mat-datepicker-toggle matSuffix [for]="picker" *ngIf="!haveOnEventClear" autocompleteoff>
      <mat-icon fontSet="material-symbols-rounded fill" matDatepickerToggleIcon>
        today
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-icon fontSet="material-symbols-rounded fill" matSuffix *ngIf="haveOnEventClear" (click)="onClear()">
      cancel
    </mat-icon>

    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
</form>