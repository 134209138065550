import { Injectable } from '@angular/core';
import * as faceapi from 'face-api.js';


@Injectable({
  providedIn: 'root'
})
export class FaceapiService {
  private modelsLoaded = false;

  constructor() { }

  
  async loadModels(): Promise<any> {
    if (!this.modelsLoaded) {
      const MODELS = await Promise.all([
        await faceapi.nets.tinyFaceDetector.loadFromUri('../../assets/models'),
        await faceapi.nets.faceLandmark68Net.loadFromUri('../../assets/models'),
        await faceapi.nets.faceRecognitionNet.loadFromUri('../../assets/models'),
        await faceapi.nets.faceExpressionNet.loadFromUri('../../assets/models')
      ]);
      this.modelsLoaded = true;
      return MODELS;
    }
  }

  async detectFace(videoElement: HTMLVideoElement) {
    return await faceapi
      .detectSingleFace(videoElement, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceDescriptor();    //Descriptor del rosto (vectores)
  }
}
