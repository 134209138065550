
import { Unit } from "./administrative";
import { Building } from "./buildings.interface";
import { User, UserRoles } from "./users.interface";

export interface StatusHistory {
  updateBy: User;
  status: EmergencyStatus;
  date: Date;
  duration?: number;
}

export enum EmergencyStatus {
  created = 'created',
  notifyVigilant = 'notify-vigilant',
  vigilantOnWay = 'vigilant-on-way',
  vigilantInUnit = 'vigilant-in-unit',
  falseAlarm = 'false-alarm',
  attended = 'attended',
  resolve = 'resolve',
  unresolve = 'unresolve',
  canceled = 'canceled',
  noCompleted = 'noCompleted',
}

export enum EmergencyType {
  security = 'security',
  infrastructure = 'infrastructure',
  health = 'health',
}

export enum EmergencySubtype {
  fire = 'fire',
  structureDamage = 'structure-damage',
  gasLeak = 'gas-leak',
  flood = 'flood',
}

export interface Emergency {
  id: string;
  status: EmergencyStatus;
  unit: Unit;
  user: User;
  building: Building;
  statusHistory: StatusHistory[];
  lastStatusAt: string;
  reason?: string;
  resolve?: boolean;
  comment?: string;
  date: Date;
  emergencyType: EmergencyType;
  subtype?: EmergencySubtype;
  roomId: string;
  userRoles?: UserRoles[];
}
