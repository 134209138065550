import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';

export interface OutputFilterDatepicker {
  startDate: string,
  endDate: string
}
@Component({
  selector: 'datepicker-library',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent implements OnInit {
  @Input() placeholderStart: string;
  @Input() placeholderEnd: string;
  @Input() autocomplete: string = 'off'

  haveOnEventClear: boolean = false;
  dateRangeForm = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
  });

  @Output() outputFilter: EventEmitter<OutputFilterDatepicker> = new EventEmitter<OutputFilterDatepicker>();
  @Output() clear: EventEmitter<boolean> = new EventEmitter<boolean>();

  params: HttpParams = new HttpParams();

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }

  selectDateRange() {
    const startDate = this.dateRangeForm.get('startDate')?.value;
    const endDate = this.dateRangeForm.get('endDate')?.value;

    if (!startDate || !endDate) return;

    const payload: OutputFilterDatepicker = {
      startDate: this.commonService.getStartDate(startDate),
      endDate: this.commonService.getEndDate(endDate)
    }

    this.outputFilter.emit(payload);
    this.haveOnEventClear = true;
  }

  onClear() {
    this.clear.emit(true);
    this.dateRangeForm.reset();
    this.haveOnEventClear = false;
  }
}
