<section class="detail__close-modal">
  <button mat-icon-butto (click)="close()"><mat-icon>close</mat-icon></button>
</section>
<section class="detail-access-modal">

  <section class="full-container p-7 face-recognition-scan">
    <div class="flex-container flex-direction-column justify-content-start w-100">
      <div style="position: relative;">
        <div class="camera-container">

          <video #videoElement muted></video>
          <canvas #canvas></canvas>
          <!-- <img [src]="photoControl.value" alt="Captured Photo" /> -->

        </div>
        <!-- Círculo de escaneo -->
        <div class="scan-circle">
          <div *ngFor="let line of scanLines; let i= index;">
            <div class="scan-line" [ngClass]="{'active': i < activeScanLines}"
              [ngStyle]="{'transform': 'rotate(' + (i * (360 / totalLines)) + 'deg) translateY(-220px)'}"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>